<template>
  <div class="h-full w-full">
    <div class="flex justify-center items-center w-full h-full relative z-0">
      <div  class="flex min-w-full w-full h-full">
        <KeyboardShortcuts />
      </div>

      <transition
        enter-active-class="transition duration-150 ease-in-out"
        leave-active-class="transition duration-150 ease-in-out"
        name="ease-in-out-modal"
      >
        <div
          v-show="displayState === 1"
          class="absolute w-full z-10 transition-all duration-300 ease-in-out bottom-0"
          :class="{
            'h-full': !hideClips,
            'h-12': hideClips
          }"
        >

          <Clips
            v-on="$listeners"
            :clips="clips"
            :displayPreviousExportsButton="Boolean(clips.length && exportedClips.length)"
            :isExporting="isExporting"
            :clipsLoading="clipsLoading"
            :selectedClip="selectedClip"
            :totalTime="totalTime"
            :isPlaying="isPlaying"
            :nonBrandkitSource="nonBrandkitSource"
            :previewClips="previewClips"
            :exportStage="exportStage"
            :additionalDuration="additionalDuration"
            @oldExportedClips="displayExported"
            @toggleClips="toggleClips"
          />
        </div>
      </transition>

      <!--To Display Old Exported Clips-->
      <transition
        enter-active-class="transition duration-150 ease-in-out"
        leave-active-class="transition duration-150 ease-in-out"
        name="slide-modal"
      >
        <div
          v-show="displayOldExports"
          class="absolute w-full h-full inset-0 flex z-20"
        >
          <ExportedList
            v-on="$listeners"
            :clips="exportedClips"
            :isExported="true"
            :fromClips="true"
            :timestamps="clips"
            :exportClip="exportClip"
            @backToClips="displayExported"
          />
        </div>
      </transition>

      <!--To Display Current Exporting Clips-->
      <transition
        enter-active-class="transition duration-150 ease-in-out"
        leave-active-class="transition duration-150 ease-in-out"
        name="ease-in-out-modal"
      >
        <div
          v-show="displayState === 2"
          class="absolute w-full max-w-2/3 max-h-full bottom-0 items-end flex z-10 transition-all duration-300 ease-in-out"
          :class="{
            'h-full': !hideExportedClips,
            'h-12': hideExportedClips
          }"
        >
          <ExportedList
            v-on="$listeners"
            :clips="exportedClips"
            :isExported="false"
            :fromClips="false"
            :timestamps="clips"
            :exportClip="exportClip"
            :isContracted="hideExportedClips"
            @toggleExported="toggleExported"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import KeyboardShortcuts from '@/view/voiceEditor/vue/components/SpextDoc/KeyboardShortcuts/KeyboardShortcuts.vue'
import Clips from './Clipper/Clips.vue'
import ExportedList from './Clipper/ExportedList.vue'
export default {
  name: 'ClipperUI',
  data() {
    return {
      displayOldExports: false,
      hideClips: false,
      hideExportedClips: false
    }
  },
  components: {
    Clips,
    ExportedList,
    KeyboardShortcuts
  },
  props: {
    isExporting: {
      type: Boolean,
      default: false
    },
    exportedClips: {
      type: Array,
      default: () => []
    },
    clips: {
      type: Array,
      default: () => []
    },
    previewClips: {
      type: Array,
      default: () => []
    },
    clipsLoading: {
      type: Boolean,
      default: false
    },
    selectedClip: {
      type: Object,
      default: () => null
    },
    totalTime: {
      type: Number,
      default: 0
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    nonBrandkitSource: {
      type: String,
      default: ''
    },
    exportStage: {
      type: Number,
      default: 1
    },
    additionalDuration: {
      type: Number,
      default: 0
    },
    makingClips: {
      type: Boolean,
      default: false
    },
    selectedClips: {
      type: Array,
      default: () => []
    },
    exportClip: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    displayExported() {
      if (this.hideClips) {
        this.toggleClips()
      }
      this.displayOldExports = !this.displayOldExports
    },
    toggleClips() {
      this.hideClips = !this.hideClips
    },
    toggleExported() {
      this.hideExportedClips = !this.hideExportedClips
    }
  },
  computed: {
    displayState() {
      if (this.clips.length) {
        return 1
      } else if (this.exportedClips.length) {
        return 2
      } else {
        return 0
      }
    }
  }
}
</script>

<style scoped>
.slide-modal-enter {
  transform: translate(100%, 0);
}
.slide-modal-leave {
  transform: translate(100%, 0);
}
.slide-modal-leave-to {
  transform: translate(100%, 0);
}
.ease-in-out-modal-enter {
  transform: translate(0, 100%);
}
.ease-in-out-modal-leave {
  transform: translate(0, 100%);
}
.ease-in-out-modal-leave-to {
  transform: translate(0, 100%);
}
</style>
