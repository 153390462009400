<template>
  <main class="flex flex-1 h-screen homeprose" :class="{'offline': offline, 'transcriptMode': isTranscriptMode}">
    <v-tour
      name="HomeProse"
      :steps="tourSteps"
      :callbacks="tourCallbacks"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="true"
          >
            <template slot="header">
              <div v-if="tour.steps[tour.currentStep].header" class="v-step__header">
                <div
                  class="text-lg leading-6 text-primary"
                  v-if="tour.steps[tour.currentStep].header.title"
                  v-html="tour.steps[tour.currentStep].header.title"
                />
              </div>
            </template>

            <template slot="content">
              <div class="v-step__content">
                <div
                  v-if="tour.steps[tour.currentStep].content"
                  v-html="tour.steps[tour.currentStep].content"
                />
              </div>
            </template>

            <template slot="actions">
              <div class="flex items-center justify-between">
                <div class="flex items-center text-xs text-primary-500">
                  <span class="text-primary">{{ tour.currentStep + 1 }}</span>
                  &nbsp;OF&nbsp;
                  <span>{{ tour.steps.length }}</span>
                </div>

                <div class="flex items-center">
                  <Button
                    @click="tour.previousStep"
                    class="mr-2.5"
                    variant="secondary"
                    :class="tour.isFirst && 'opacity-20 pointer-events-none'"
                  >
                    Previous
                  </Button>
                  <Button
                    v-if="!tour.isLast"
                    @click="tour.nextStep"
                    class="pr-2"
                    variant="primary"
                  >
                    <span class="flex items-center" >
                      <div>Next</div>
                      <ChevronRight classname="ml-0.5" />
                    </span>
                  </Button>
                  <Button
                    v-if="tour.isLast"
                    @click="tour.finish"
                    class=""
                    variant="primary"
                  >
                    START EDITING!
                  </Button>
                </div>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
    <div
      v-if="fullscreen"
      class="absolute flex items-center justify-center w-screen h-screen bg-black z-infinity group"
    >
      <VideoPlayer
        id="fullscreen-ce-player"
        :subtitle="podcastInfo.hasOwnProperty('subtitle') && podcastInfo.subtitle ? podcastInfo.subtitle : subtitle"
        :debug="false"
        :autoplay="true"
        :loading="loading === 'true' || !downloadedAssets"
        :subtitleStyle="podcastInfo.hasOwnProperty('subtitleStyle') ? podcastInfo.subtitleStyle : null"
        :showThumbnail="false"
        :type="playerType"
        :videoPlayerInfo="videoPlayerInfo"
        @goToTime="goToTime"
      />
    </div>

    <Header
      v-show="!isIframe"
      @helpSelected="helpSelected"
      @back="goToDrive"
    />
    <div
      class="relative grid w-full grid-cols-10 gap-4"
      :class="{'ml-16 pl-3': !isIframe}"
    >
      <section
        class="relative z-10 flex flex-col h-screen bg-white shadow-lg"
        :class="{
          'col-span-10': isDemo || isIframe || isLibSyn,
          'col-span-6': !isDemo && podcastInfo && hasVideo,
          'col-span-7': !isDemo && podcastInfo && !hasVideo
        }"
      >
        <div v-show="loading === 'true' || !downloadedAssets" class="h-screen" />
        <div v-show="(loading === 'true' || !downloadedAssets) || exportLoading" class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center h-screen bg-white bg-opacity-80 z-99">
          <div class="px-8 py-2 bg-white rounded-lg shadow-lg">{{ isDemo ? 'Try a Sample file...' : 'Loading...'}}</div>
        </div>

        <div v-show="isEditorBlocked" class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center h-screen bg-white bg-opacity-80 z-99">
          <div v-if="editorBlockReason" class="px-8 py-2 bg-white rounded-lg shadow-lg">
            {{editorBlockReason}}
          </div>
        </div>

        <div v-show="loading==='false'" id="editorSection" class="flex-1">
          <transition enter-active-class="animated slideInUp" leave-active-class="animated slideOuDown">
            <div v-if="offline" class="absolute z-30 flex justify-center w-full bottom-18">
              <div  class="px-4 py-1 text-white rounded-lg bg-primary-700">
                Offline: Trying to connect. Please keep tab open.
              </div>
            </div>
          </transition>

          <!-- editor begins -->
          <div class="relative w-full">
            <div
              v-if="!(isDemo || isIframe)"
              class="relative flex items-center justify-between pt-10 pl-10 pr-10 mb-4 bg-white"
              :class="{'z-40': !touring}"
            >
              <input
                v-if="isEditingTitle"
                type="text"
                placeholder="Name Your Doc"
                class="flex-grow p-0 titleInputText font-semibold border-none outline-none font-h placeholder-primary-300 titleInput focus:ring-0"
                v-model="title"
                v-focus
                @blur="isEditingTitle = false;"
                @keyup.enter="isEditingTitle=false;"
              >
              <div
              v-else
              @click="isEditingTitle = true;"
              class="flex-grow overflow-hidden titleInputText font-semibold font-h whitespace-nowrap overflow-ellipsis sp-0"
              :class="{'text-primary-300': !title, 'titleInput': !isDemo}"
              >
                {{title || 'Name Your Doc'}}
              </div>

              <div class="flex items-center">
                <!-- <div v-if="fileState" class="relative w-20 mr-3 text-center">
                  <div
                  class="w-20 text-sm italic"
                  :class="{'text-green': fileState.type === 'success', 'text-red': fileState.type === 'error','text-primary-600': !fileState.type}"
                  >
                    {{fileState.value}}
                  </div>

                  <div v-if="!fileState.type" class="top-1.5 fileStateLoader relative">
                    <div class="line" />
                  </div>
                </div> -->
                <div class="flex items-center"
                  v-show="!isLibSyn"
                >
                  <IconButton
                    v-show="!isIframe"
                    classname="mr-2"
                    @click="toggleSearchPanel"
                    v-tooltip.top="'Search'"
                    size="large"
                    :variant="panelToShow === 'search' ? 'active' : 'bordered'"
                  >
                    <SearchIcon classname="h-6 w-6" />
                  </IconButton>

                  <IconButton
                    v-show="!isIframe"
                    classname="mr-2"
                    @click="toggleCommentsPanel()"
                    v-tooltip.top="'Comments'"
                    size="large"
                    :variant="panelToShow === 'comments' ? 'active' : 'bordered'"
                  >
                    <CommentIcon class="w-6 h-6" />
                  </IconButton>
                </div>

                <Button
                  v-show="!isIframe"
                  variant="secondary"
                  classname="mr-2"
                  @click="openInviteModal()"
                >
                  Invite
                </Button>

                <v-popover
                  v-show="!isIframe || isLibSyn"
                  offset="16"
                  placement="bottom-end"
                  popoverClass="popover-sm"
                >
                  <Button classname="publishButton">
                    <span ref="export" class="flex items-center" >
                      <div>Export</div>
                      <ChevronDownIcon classname="ml-2 publishButtonArrow" />
                    </span>
                  </Button>

                  <template slot="popover">
                    <div class="px-3 py-2 exportdropdown">
                      <!-- eslint-disable-next-line vue/require-v-for-key -->
                      <div v-for="item in exportDropdownOptions" v-close-popover @click="exportAs(item.format)">
                        <div class="flex items-center justify-between p-2 rounded cursor-pointer group hover:bg-primary-200">
                          <div class="text-sm text-primary">{{item.title}}</div>
                          <div class="p-1 font-bold rounded-sm bg-primary-200 text-2xs text-primary-500 group-hover:bg-white">{{item.format.toUpperCase()}}</div>
                        </div>
                        <div class="my-1 border-t border-random-F2F2F2"/>
                      </div>

                      <div
                        class="flex items-center justify-between p-2 rounded cursor-pointer text-red hover:bg-primary-200"
                        v-close-popover
                        @click="togglePanel({ panel: 'exports', forceShow: true })"
                      >
                        <div class="text-sm font-medium">View all exports</div>
                        <ChevronRight classname="h-4 w-4" />
                      </div>

                      <!-- <div
                        class="flex items-center py-2 pl-1 pr-2 rounded cursor-pointer text-red hover:bg-primary-200"
                        v-close-popover
                        @click="togglePanel({ panel: 'exports', forceShow: true })"
                      >
                        <BrandIcon classname="brand22 mr-2.5" />
                        <div class="text-sm font-medium">View all exports</div>
                      </div> -->

                      <!-- <div v-if="publicAccess" class="my-1 border-t border-gray"/>
                      <div
                        v-if="publicAccess"
                        class="flex items-center py-2 pl-1 pr-2 rounded cursor-pointer text-red hover:bg-primary-200"
                        v-close-popover
                        @click="copyLink"
                      >
                        <BrandIcon classname="mr-2.5" />
                        <div class="text-sm">Publish to Spext</div>
                      </div> -->
                    </div>
                  </template>
                </v-popover>
              </div>

            </div>

            <div
              class="pl-10 overflow-y-auto"
              :class="{'pt-9 pr-10': isDemo || isIframe, 'pr-6 pt-6 mr-4': !(isDemo||isIframe)}"
              :style="{height: isDemo || isIframe ? 'calc(100vh - 56px)': (isBlockmodeOpen ? 'calc(100vh - 343px)' : 'calc(100vh - 154px)')}"
              id="editorContainer"
              ref="editorContainer"
            >
              <div id="proseText" :style="{paddingBottom: isDemo || isIframe ? '40px': '224px'}" :class="{'pt-2': !(isDemo || isIframe)}">
                <ProseEditor
                  @loadingState="setLoadingState"
                  :autocomplete="spellcheck"
                  :autocorrect="spellcheck"
                  :autocapitalize="spellcheck"
                  :spellcheck="spellcheck"
                  @json="setDOCC"
                  @sources="updateSources"
                  @addClip="handleClip"
                  ref="prose"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="sticky bottom-0 left-0 right-0">
          <div class="relative w-full editor-footer-overlay">
            <div class="absolute left-0 right-0 footerlay" />
            <div class="absolute left-0 right-0 footershadow"/>
          </div>

          <transition v-if="!(isDemo || isIframe)" enter-active-class="animated slideInUp" leave-active-class="animated slideOuDown">
            <BlockModeV2 v-if="isBlockmodeOpen" />
          </transition>

          <Footer
            v-show="loading==='false'"
            :playerLoadProgress="playerLoadProgress"
            :totalTime="totalTime"
            :isPlaying="isPlaying"
            :touring="touring"
            :showAutopilotTooltip="showAutopilotTooltip"
            @goToPara="goToPara"
            @goToTime="goToTime"
            @removeAutopilotTooltip="showAutopilotTooltip = false;"
            @backward="backward"
            @forward="forward"
            @toggleState="toggleState"
            @toggleReduceNoise="toggleReduceNoise"
          />
        </div>

        <div @click="downloadInProgress=0" v-show="downloadInProgress>0" class="fixed ui floating icon message">
          <i style="font-family: 'Font Awesome 5 Pro';" class="far fa-info-circle icon"></i>
          Download will start automatically in a moment..
        </div>

        <div v-show="dirtyMessage>0" class="fixed ui floating icon message">
          <i style="font-family: 'Font Awesome 5 Pro';" class="far fa-info-circle icon" />
          <span v-html="myMessage" />
        </div>

        <modal-chrome :isMobile="isMobile" />

        <vue-toastr ref="toastr" />
      </section>

      <section v-show="!(isDemo || isIframe) && !isLibSyn" class="relative flex flex-col h-screen overflow-hidden bg-white border-l border-primary-300"
        :class="{
          'col-span-4': podcastInfo && hasVideo,
          'col-span-3': podcastInfo && !hasVideo
        }"
      >
        <div class="relative flex items-center justify-center thumbnail group"
          :class="{
            'thumbnail_video': podcastInfo && hasVideo
          }"
        >
          <HTMLVideoPlayer
            v-if="exportClip"
            :key="exportClip.uid"
            :id="`exportClip-${exportClip.uid}`"
            :path="exportClip.url"
            :title="exportClip.title"
            ref="exportPlayer"
            @onPlayToggle="onExportPlayToggled"
          />
          <PreviewPlayer
            v-if="podcastInfo && (debug || hasVideo) && !fullscreen && !isEditorBlocked"
            v-show="!exportClip"
            ref="previewPlayer"
            id="previewPlayer"
            :loading="String(Boolean(loading === 'true' || !downloadedAssets))"
            :from="exportStage<3 && selectedClip ? selectedClip.start : 0"
            :to="getEndTimeValue"
            :videoPlayerInfo="videoPlayerInfo"
            :debug="false"
            :subtitleStyle="exportStage === 3 && useBrandkit && brandkit && brandkit.enableSubtitleFormatting ? (brandkit.subtitleStyle || {}) : null"
            :showSeeker="true"
            :customClickAction="true"
            :showVideoPlayer="true"
            :disableInteraction="exportLoading"
            :title="selectedClip ? selectedClip.title : title"
            :playerType="podcastInfo.hasOwnProperty('playerType') ? podcastInfo.playerType : 'video'"
            @onTogglePlay="toggleState"
            @goToTime="goToTime"
          />
        </div>

        <div v-show="panelToShow === 'shortcuts'" class="relative rp-height bg-white panel">
          <!-- <div v-if="defaultPanel !== 'shortcuts'" class="absolute z-20 cursor-pointer top-5 right-4 text-primary-500 hover:text-primary-700" @click="togglePanel({ panel: 'exports' })" >
            <CloseIcon classname="h-5 w-5" />
          </div> -->
          <!-- <KeyboardShortcuts /> -->
          <Wrapper v-if="!docLoading && !freshDoc && user && !user.isAnonymous && podcastInfo && hasVideo && podcastInfo.media_id" ref="clipper"/>
          <KeyboardShortcuts v-else />
        </div>

        <div v-if="['fillerWords', 'silence'].includes(panelToShow)" class="relative bg-white panel">
          <div class="absolute z-20 cursor-pointer top-5 right-4 text-primary-500 hover:text-primary-700" @click="togglePanel({ panel: panelToShow })" >
            <CloseIcon classname="h-5 w-5" />
          </div>
          <AutopilotWords :type="panelToShow" />
        </div>

        <div v-if="panelToShow === 'exports'" class="relative bg-white panel">
          <div class="absolute z-20 cursor-pointer top-5 right-4 text-primary-500 hover:text-primary-700" @click="togglePanel({ panel: 'exports' })" >
            <CloseIcon classname="h-5 w-5" />
          </div>
          <ExportedFiles />
        </div>

        <div v-if="panelToShow === 'search'" class="relative bg-white panel">
          <div class="absolute z-20 cursor-pointer top-5 right-6 text-primary-500 hover:text-primary-700" @click="togglePanel({ panel: 'search' })" >
            <CloseIcon classname="h-5 w-5" />
          </div>
          <EditorSearch />
        </div>

        <div v-show="panelToShow === 'comments'" class="relative panel">
          <div v-if="defaultPanel !== 'comments'" class="absolute z-20 cursor-pointer top-5 right-4 text-primary-500 hover:text-primary-700" @click="togglePanel({ panel: 'comments' })" >
            <CloseIcon classname="h-5 w-5" />
          </div>
          <SideBarComments />
        </div>

        <div v-if="isBlockmodeOpen && !isDemo" class="relative bg-white panel">
          <div class="absolute z-20 cursor-pointer top-5 right-4 text-primary-500 hover:text-primary-700" @click="togglePanel({ panel: 'blockmode' });" >
            <CloseIcon classname="h-5 w-5" />
          </div>
          <BlockmodePanel />
        </div>

      </section>
    </div>

    <AudioController
      :loading="loading"
      :urlList="urlList"
      :videoUrlList="videoUrlList"
      :imageUrlList = "imageUrlList"
      :playerType="playerType"
      ref="audioController"
    />

    <!-- Popovers -->
    <AddActionPopover />
    <ShareModal />
    <PublishModal />
    <TippyTours />

    <TooltipMenuHintPopup
      v-if="showFinalTour"
      :domBoundaryRect="tourFinalBoundaryRect"
      :onComplete="onEndFinalOnboarding"
    />
    <div
      v-show="podcastInfo && podcastInfo.audio_editor === 'processing'"
      class="fixed left-0 top-0 w-screen h-screen over-everything bg-red-200 flex justify-center items-center"
    >
      <div class="bg-white rounded-xl w-80 h-64 border-primary-300 border-2 border-solid custom-shadow flex justify-center items-center flex-col">
        <div class="flex justify-center items-center relative w-18">
          <img src="../../../../assets/images/loader_logo.png" alt="spext logo" class="w-7 h-auto">
          <img src="../../../../assets/images/loading_circle.png" alt="loader" class="absolute left-1/2 top-1/2 w-full h-auto rotating" />
        </div>
        <h2 class="text-primary font-ui font-semibold text-lg text-center mt-9">
          Preparing the editor experience
        </h2>
        <span class="text-sm text-primary-500 font-ui text-center">
          We should be ready in a few minutes!
        </span>
      </div>
    </div>
  </main>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import axios from 'axios'
import prettyMs from 'pretty-ms'
import debounce from 'lodash/debounce'
import random from 'lodash/random'
import CONSTANTS from '@/constants/CONSTANTS'
import store from '@/services/store'
import { mapGetters, mapActions } from 'vuex'
import { copyToClipboard, exportAsFile, formatAsSubtitle, formatAsSubtitleNew } from '@/utilities/utility'
import Clipper from '@/view/voiceEditor/proseEditor/workflows/clipper'
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!../../../../services/api/transcript' // workerize loader is a third party lib we use to get javascript inside the worker
import { getPodcastMeta, saveExportedFileMeta, updatePodcast } from '@/services/api/podcast'
import { setOnboardingState } from '@/services/api/account'
import { getModeFromLocalStorage, getExportMessageDontAsk } from '@/components/common/browserStorageUtility'
import { formatToProse } from './formatter'
import ProseEditor from './NewProseEditor'
import AudioController from './AudioController'
import { calculatePercentage } from './utility/utility.ts'
import { tippyTourAddClickPlay, tippyTourDeleteAudio, tippyTourSignUp } from './tippy-popovers.js'
import TippyTours from './TippyTours.vue'
import { removeAllSearchHighlights, removeAllSelections } from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'
import {
  disableFillerWords,
  trimSilence
} from '@/view/voiceEditor/proseEditor/util/smartEditUtilities'
import { getImageFromPath } from '@/components/VideoPlayer/playerElementsData'
import ChevronDownIcon from '@/components/base/icons/ChevronDown.vue'
import { myProseEditor, removeAllClipStuff, toggleTranscriptMode } from '@/view/voiceEditor/proseEditor/util/utility'
import VideoPlayer from '@/components/VideoPlayer/index.vue'
import ChevronRight from '@/components/base/icons/ChevronRight.vue'
import SearchIcon from '@/components/base/icons/Search.vue'
import CommentIcon from '@/components/base/icons/Comment.vue'
import IconButton from '@/components/base/buttons/IconButton'
import TooltipMenuHintPopup from './TooltipMenuHintPopup.vue'
import Wrapper from '@/view/voiceEditor/vue/components/Clipper/wrapper.vue'
import PreviewPlayer from '@/view/Workflows/PreviewPlayer/index.vue'
import HTMLVideoPlayer from '@/view/Workflows/components/HtmlVideoPlayer.vue'
require('@fortawesome/fontawesome-pro/css/all.css')
const { compressStateJSON } = require('prosemirror-compress')
const uuidv1 = require('uuid/v1')
let instance = worker()

// instance. results a promise. always use it with await
let docc = null
const errors = {
  200: `You will get an email when it's ready. Check notifications to see progress...`,
  201: `Preparing the file for export...`,
  202: 'Preparing Audio, please wait...',
  203: 'Great!<br>Audio submitted for Post Production.<br>You will receive an email when it is ready.',
  204: 'Download will start automatically in a moment..',
  400: 'Right click is disabled, to cut/copy/paste use keyboard shortcuts',
  403: 'You don’t have permission to open this doc for editing. Please contact the owner of the doc.',
  404: 'Looks like this doc is empty. We are unable to open it for editing',
  500: 'Error: Please contact support',
  501: 'Not allowed on Mobile Browser',
  502: 'Unable to download. Please try later.',
  503: 'Media File missing, it will be skipped',
  504: 'This is the voice editor.<br/> Only enter, space & backspace are active.',
  505: 'Saving. Please try after some time'
}

const editTranscriptExports = [
  {
    title: 'Plain Text',
    format: 'txt'
  },
  {
    title: 'Word Document',
    format: 'doc'
  },
  {
    title: 'Subtitle',
    format: 'srt'
  },
  {
    title: 'WebVTT',
    format: 'vtt'
  }
]
const editMediaExports = [
  {
    title: 'Export .wav',
    format: 'wav'
  },
  {
    title: 'Export .flac',
    format: 'flac'
  },
  {
    title: 'Export .mp3',
    format: 'mp3'
  },
  {
    title: 'Export .mp4',
    format: 'mp4'
  }
  // {
  //   title: 'Adobe Audition session',
  //   format: 'sesx'
  // }
]

export default {
  components: {
    VideoPlayer,
    ChevronDownIcon,
    // BrandIcon,
    'vue-toastr': () => import('vue-toastr'),
    'modal-chrome': () => import('@/components/ModalChrome'),
    ProseEditor,
    Header: () => import(/* webpackChunkName: "Header" */'../../../../components/Drive/core/Header.vue'),
    Footer: () => import(/* webpackChunkName: "Footer" */'./SpextDoc/footer/Footer'),
    AudioController: AudioController,
    AddActionPopover: () => import(/* webpackChunkName: "AddActionPopover" */'./AddActionPopover'),
    BlockModeV2: () => import(/* webpackChunkName: "BlockModeV2" */'./BlockModeV2/index.vue'),
    ShareModal: () => import(/* webpackChunkName: "ShareModal" */'../../../../components/Share'),
    PublishModal: () => import(/* webpackChunkName: "ModalPublish" */'./Publish/ModalPublish'),
    SideBarComments: () => import(/* webpackChunkName: "SiderBarComments" */'./SideBarComments'),
    TippyTours,
    KeyboardShortcuts: () => import(/* webpackChunkName: "KbShortcuts" */'./SpextDoc/KeyboardShortcuts/KeyboardShortcuts.vue'),
    EditorSearch: () => import(/* webpackChunkName: "EditorSearch" */'./SpextDoc/EditorSearch.vue'),
    ExportedFiles: () => import(/* webpackChunkName: "ExportedFiles" */'./SpextDoc/ExportedFiles.vue'),
    AutopilotWords: () => import(/* webpackChunkName: "AutopilotWordsPanel" */'./SpextDoc/AutopilotWords.vue'),
    BlockmodePanel: () => import(/* webpackChunkName: "BlockmodePanel" */'./BlockModeV2/BlockmodePanel.vue'),
    Button: () => import(/* webpackChunkName: "Popover" */'@/components/base/buttons/Button.vue'),
    CloseIcon: () => import(/* webpackChunkName: "CloseIcon" */'@/components/base/icons/Close.vue'),
    ChevronRight,
    SearchIcon,
    CommentIcon,
    IconButton,
    TooltipMenuHintPopup,
    Wrapper,
    PreviewPlayer,
    HTMLVideoPlayer
  },
  name: 'HomeProse',
  data () {
    return {
      playerDirty: false,
      touring: false,
      tourCallbacks: {
        onStart: this.onTourStart,
        onSkip: this.onTourEnd,
        onFinish: this.onTourEnd
      },
      random,
      isEditingTitle: false,
      fileState: null,
      downloadInProgress: 0,
      podcastDeleted: false,
      devMode: CONSTANTS.devMode,
      loading: 'false',
      shown: false,
      list: [],
      value: 0,
      podId: '',
      saving: '',
      offline: false,
      waitMessage: 'Preparing Audio, please wait...',
      myMessage: 'somemessage',
      trackMode: false,
      spellcheck: CONSTANTS.ENABLE_SPELL_CHECK,
      urlList: {},
      videoUrlList: {},
      imageUrlList: {},
      podcastList: null,
      ownerId: '',
      loadingUrlList: {},
      onlineRef: '',
      downloadedAssets: true,
      isIframe: (window.location !== window.parent.location),
      now: Date.now(),
      intervalId: 0,
      selectedExportFormat: '',
      showAutopilotTooltip: false,
      subtitle: [],
      isLibSyn: false,
      debug: this.$route.query.debug === 'true',
      fullscreen: this.$route.query.fullscreen === 'true',
      playFrom: this.$route.query.from,
      playTo: this.$route.query.to,
      currentTourStep: null,
      tourSteps: [
        {
          target: '#v-step-0',
          header: {
            title: 'Deleting text in the transcript cuts media ⚡️'
          },
          content: `
          <div class="text-sm">
            <p class="text-primary-600">To correct the transcript, you can do any of the following:</p>
            <ol class="list-decimal pl-4.5">
              <li class="pl-0.75 mb-0.75">Use the keyboard shortcut <strong class="font-semibold">(Cmd+K)</strong></li>
              <li class="pl-0.75 mb-0.75">Double-click the word in the transcript</li>
              <li class="pl-0.75 gap-2">Click
                <div class="inline-flex -mt-4 relative" style="bottom: -0.55rem">
                  <svg width="30" height="27" viewBox="0 0 42 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.597656" width="34.0006" height="34.0006" rx="8.40112" fill="#F3F4F6"/>
                  <path d="M10.2002 25.2481C10.2002 24.7787 10.5808 24.3981 11.0502 24.3981H22.9504C23.4199 24.3981 23.8004 24.7787 23.8004 25.2481C23.8004 25.7176 23.4199 26.0982 22.9504 26.0982H11.0502C10.5808 26.0982 10.2002 25.7176 10.2002 25.2481Z" fill="#000228"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3505 10.096C18.6783 8.76821 20.8311 8.76821 22.1589 10.096L22.8027 10.7398C24.1305 12.0676 24.1305 14.2204 22.8027 15.5482L16.9324 21.4184C16.6966 21.6543 16.3966 21.8155 16.0698 21.8821L12.2417 22.6624C11.0478 22.9057 9.99309 21.851 10.2364 20.6571L11.0165 16.8289C11.0831 16.5021 11.2444 16.2021 11.4802 15.9662L17.3505 10.096ZM20.9568 11.2981C20.2929 10.6342 19.2165 10.6342 18.5526 11.2981L18.2027 11.648L21.2507 14.696L21.6006 14.3461C22.2645 13.6822 22.2645 12.6058 21.6006 11.9419L20.9568 11.2981ZM13.9526 15.8981L17.0006 12.8501L20.0486 15.8981L17.0006 18.946L13.9526 15.8981ZM12.7505 17.1002L12.6823 17.1684L11.9022 20.9966L15.7303 20.2163L15.7985 20.1481L12.7505 17.1002Z" fill="#000228"/>
                  <circle cx="31.7354" cy="29" r="9" fill="#000228" stroke="white" stroke-width="2"/>
                  <path d="M27.8675 25.7273H29.7638L31.7666 30.6136H31.8518L33.8547 25.7273H35.751V33H34.2595V28.2663H34.1991L32.317 32.9645H31.3014L29.4193 28.2486H29.3589V33H27.8675V25.7273Z" fill="white"/>
                  </svg>
                </div>
              button</li>
            </ol>
          </div>`
        },
        {
          target: '#v-step-1',
          header: {
            title: 'Autopilot has removed the filler words and silences 😉'
          }
        }
      ],
      showFinalTour: false,
      tourFinalBoundaryRect: null,
      tourFinalNode: null,
      tourFinalDone: false,
      tourNodeHightlight: { from: null, to: null },
      tourInitiated: false
    }
  },
  watch: {
    user: function (val) {
      console.log('checkhere for user', val)
    },
    previewVideoStatus: function(val) {
      if (val && this.podcastInfo && !this.hasVideo) {
        this.updatePodcastWithVideoInfo()
      }
    },
    authToken: function(val) {
      if (val) {
        instance.initAuth(val)
      }
    },
    isPlaying: function (val) {
      if (val) this.playerDirty = true
    },
    docLoading: function (val, oldVal) {
      if (!this.isDemo && !val && oldVal) {
        this.checkInitialOnboarding()
      }
      if (!val) {
        setTimeout(() => {
          removeAllClipStuff(myProseEditor.view)
          myProseEditor.clipperActive = Boolean(this.clips.length)
        }, 100)
      }
    },
    clips(val) {
      if (!myProseEditor) return
      myProseEditor.clipperActive = Boolean(val.length)
    },
    videoPlayerInfo: function(val) {
      const { backgroundImage, coverArt } = { ...val }
      if (backgroundImage && backgroundImage.url) getImageFromPath(backgroundImage.url)
      if (coverArt && coverArt.url) getImageFromPath(coverArt.url)
    },
    editMode: function(val, oldVal) {
      if (val !== oldVal) {
        this.checkInitialOnboarding()
        if (oldVal === 'editMedia') this.closeBlockmode()
      }
    },
    panelToShow: function(val, oldVal) {
      if (val !== oldVal && oldVal === 'blockmode') this.closeBlockmode(false)
    },
    showCopied: function(val, oldVal) {
      if (val === true && oldVal === false) this.showToast(`Copied, use ${this.meta}+V to paste`)
    },
    showCut: function(val, oldVal) {
      if (val === true && oldVal === false) this.showToast(`Cut, use ${this.meta}+V to paste`)
    },
    timeEdited: function(val, oldVal) {
      if (val !== 0 && oldVal === 0) this.showToast(`${val > 0 ? '+' : '-'}${prettyMs(Math.abs(this.timeEdited))}`, val > 0 ? 'success' : 'error')
    },
    checkPoint1 (val) {
      store.commit('editor/addToStep', 'filler')
      tippyTourDeleteAudio()
    },
    checkPoint2 (val) {
      store.commit('editor/addToStep', 'reduceNoise')
    },
    playingComplete (val) {
      if (val && this.isDemo) tippyTourSignUp()
    },
    savingStep (val) {
      if (!val) this.updatePodcastInfo()
    },
    dirtyMessage () {
      this.myMessage = `Can not switch mode while saving<br>Please wait...`
    },
    playerLoading () {
      this.showMessage(202)
    },
    mediaMissing () {
      this.showMessage(503)
    },
    newDoc (val) {
      if (val.length > 0) this.addDoc(val)
    },
    textAddAttempt () {
      this.showMessage(504)
    },
    playerLoadProgress: function (progress) {
      if (this.loading === 'false') this.updateProgress(progress)
    },
    downloadedAssets: function (val, oldVal) {
      if (val && !oldVal) {
        this.fileState = {
          value: 'Done',
          type: 'success'
        }
        this.syncSubtitle()
        setTimeout(() => {
          this.fileState = null
        }, 800)
      }
      if (!val) {
        this.fileState = {
          value: 'Buffering...'
        }
      }
    },
    loading: function(val, oldVal) {
      if (val !== 'saving' && oldVal === 'saving') {
        this.fileState = {
          value: 'Saved',
          type: 'success'
        }
        this.syncSubtitle()
        setTimeout(() => {
          this.fileState = null
        }, 800)
      }
      if (val === 'saving' && oldVal !== 'saving') {
        this.fileState = {
          value: 'Saving...'
        }
      }
    },
    saving: function(val, oldVal) {
      if (!val && oldVal) {
        this.fileState = {
          value: 'Saved',
          type: 'success'
        }
        this.syncSubtitle()
        setTimeout(() => {
          this.fileState = null
        }, 800)
      }
      if (val && !oldVal) {
        this.fileState = {
          value: 'Saving...'
        }
      }
    },
    userOnboardingState: {
      handler(val) {
        this.currentTourStep = val
      },
      immediate: true
    },
    currentTime: function(currentTime) {
      if (this.tourFinalDone || !this.tourInitiated) return
      const shouldShowOnboarding = (this.shouldShowOnboarding() !== false) && ['4', '5'].includes(this.currentTourStep)
      if (!shouldShowOnboarding && this.tourInitiated) {
        this.tourFinalDone = true
        return
      }

      const maxDuration = 2
      const minWordLength = 4
      const view = myProseEditor.view
      const state = view.state

      let editorPos = state.selection.$anchor.pos
      let posDom = view.nodeDOM(editorPos)
      const shouldShowTour = Math.round(currentTime) > maxDuration && posDom.length > minWordLength
      if (!this.isDemo && !this.fullscreen && shouldShowOnboarding && shouldShowTour) {
        if (this.isPlaying) this.toggleState()
        const nodeFrom = editorPos
        const nodeTo = editorPos + posDom.length
        this.tourNodeHightlight = { from: nodeFrom, to: nodeTo }

        const transaction = state.tr
        transaction.addMark(nodeFrom, nodeTo, state.schema.marks.searchHighlight.create())
        view.dispatch(transaction)

        editorPos = state.selection.$anchor.pos
        posDom = view.nodeDOM(editorPos)

        let node = posDom.parentElement
        if (node.classList.contains('playbackSelection')) node = node.parentElement
        this.tourFinalNode = node
        setTimeout(() => this.onStartFinalOnboarding(node), 50)
      }
    }
  },
  created () {
    updatePodcast(this.$route.params.userId, this.$route.params.podId, { opened: Date.now() })
    window.projects = false
    // logic for demo on landing page
    var vm = this
    vm.ownerId = vm.$route.params.userId
    store.commit('editor/setOwnerId', vm.ownerId)
    vm.podId = vm.$route.params.podId

    if (vm.ownerId === CONSTANTS.demoUserid && vm.podId === CONSTANTS.demoMediaid) {
      store.commit('editor/setIsDemo', true) // decide logi
    }

    // if (window.Intercom && (this.isIframe || this.isMobile || this.fullscreen)) { // if iframe don't show intercom
    //   window.Intercom('update', {
    //     'hide_default_launcher': true
    //   })
    // }
    if (window.Intercom) {
      window.Intercom('update', {
        'hide_default_launcher': true
      })
    }
  },
  mounted () {
    if (this.authToken) {
      instance.initAuth(this.authToken)
    }
    store.commit('video/commitPreviewVideoStatus', false)
    window._spext_transcriptMode = false
    store.commit('editor/setTranscriptMode', false)
    window.analytics.track('editor_mediaEditorOpen', {
      podId: this.$route.params.podId,
      userId: this.$route.params.userId
    })
    // $(document.body).css('zoom', '100%')
    var vm = this
    if (vm.$route.query.libsyn) {
      this.isLibSyn = true
    }

    // if path has token, sign in using that account
    if (vm.$route.query.token) {
      // this was required for old export, not using it now ( double check later )
      const token = vm.$route.query.token
      firebase.auth().signInWithCustomToken(token).then(function (authInfo) {
        console.log('auth info is', authInfo)
        console.log('user is', authInfo.user)
        let user = authInfo.user
        vm.getPodcastList(user.uid).then(function (snapshot) {
          vm.podcastList = snapshot.val()
          vm.initPodcast()
        })
      }).catch(function (error) {
        console.log(error)
      })
    } else {
      // when click on create a new doc
      if (vm.isNewDoc()) {
        store.commit('editor/setFreshDoc', true)
        store.commit('editor/setPodcastInfo', { ...this.podcastInfo, audio_editor: true })
        console.log('create a new doc')
        this.loading = 'true'
        let emptyDocument = {
          docVersion: 2,
          type: 'doc',
          content: [{
            type: 'paragraph',
            attrs: {
              labelName: '',
              labelColor: ''
            },
            content: []
          }]
        }
        vm.$refs.prose.setContent({ doc: emptyDocument, k: -1 }, true)
        this.loading = 'false'
        firebase.database().ref(firebase.auth().currentUser.uid + '/podcast-info/' + this.podId)
          .update({ version: 2 }).then(() => {
            store.commit('editor/setDocVersion', 2)
            console.log('written to db. Updated podcastInfo')
          }).catch((err) => {
            console.log('unable to save', err)
          })
      } else {
        vm.initPodcast()
      }
      if (!vm.$parent.isCompatibleBrowser || vm.isMobile) {
        vm.showChromeModal()
      }
      // listen for keyboard actions
      vm.switchOnListening()
    }
    vm.updateContactInfo() // updating collaborator information
  },
  // originalOverallDuration () {
  //   if (store.state.editor.podcastInfo && store.state.editor.podcastInfo.length) {
  //     return store.state.editor.podcastInfo.length
  //   }
  //   return 0
  // },
  computed: {
    ...mapGetters({
      previewVideoStatus: 'video/previewVideoStatus',
      isEditorBlocked: 'editor/isEditorBlocked',
      editorBlockReason: 'editor/editorBlockReason',
      isBlockmodeOpen: 'editor/isBlockmodeOpen',
      isNoiseReductionEnabled: 'smartEditConfig/isNoiseReductionEnabled',
      nrConfig: 'smartEditConfig/nrConfig',
      podcastInfoExists: 'editor/podcastInfoExists',
      user: 'app/user',
      billing: 'app/billing',
      masterTranscriptVolume: 'editor/masterTranscriptVolume',
      isMobile: 'app/isMobile',
      checkPoint1: 'editor/checkPoint1',
      checkPoint2: 'editor/checkPoint2',
      playingComplete: 'editor/playingComplete',
      steps: 'editor/steps',
      isDemo: 'editor/isDemo',
      podcastInfo: 'editor/podcastInfo',
      browserIs: 'app/browserIs',
      timeDeleted: 'editor/timeDeleted',
      dirtyMessage: 'editor/dirtyMessage',
      dirty: 'editor/dirty',
      savingStep: 'editor/savingStep',
      editMode: 'editor/editMode',
      meta: 'app/meta',
      uploadedMedia: 'app/uploadedMedia',
      playerLoading: 'editor/playerLoading',
      mediaMissing: 'editor/mediaMissing',
      freshDoc: 'editor/freshDoc',
      newDoc: 'doc/newDoc',
      isPlaying: 'editor/isPlaying',
      playerLoadProgress: 'editor/playerLoadProgress',
      totalTime: 'editor/totalTime',
      textAddAttempt: 'editor/textAddAttempt',
      timeEdited: 'editor/timeEdited',
      showCut: 'editor/showCut',
      showCopied: 'editor/showCopied',
      panelToShow: 'editor/panelToShow',
      threadCount: 'editor/threadCount',
      defaultPanel: 'editor/defaultPanel',
      currentTime: 'doc/currentTime',
      isTranscriptMode: 'editor/isTranscriptMode',
      openModalName: 'dialogs/modalName',
      notificationsOpen: 'modal/showNotifications',
      userOnboardingState: 'app/onboardingState',
      clips: 'clipper/clips',
      useBrandkit: 'clipper/useBrandkit',
      brandkit: 'clipper/brandkit',
      selectedClip: 'clipper/selectedClip',
      exportStage: 'clipper/exportStage',
      exportClip: 'clipper/exportClip',
      exportLoading: 'clipper/exportLoading',
      videoReady: 'video/sourcesReady',
      authToken: 'customAuth/authToken'
    }),
    progressOutOf100: {
      get() {
        const max = this.totalTime || 1000
        const min = 0
        const range = max - min
        if (range) {
          if (this.currentTime >= max) return 100
          return ((this.currentTime - min) / range) * 100
        }
        return 0
      },
      set(newVal) {
        const max = this.totalTime || 1000
        const min = 0
        const range = max - min
        if (range) {
          const seekedTime = min + ((range * newVal) / 100)
          this.goToTime(seekedTime)
        }
      }
    },
    docLoading: function() {
      return this.loading === 'true' || !this.downloadedAssets
    },
    videoPlayerInfo () {
      const {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio,
        template
      } = { ...this.podcastInfo }
      const info = {
        backgroundImage,
        coverArt,
        subtitleStyle,
        enableSubtitleFormatting,
        heading,
        aspectRatio,
        template
      }

      return info
    },
    playerType() {
      return this.podcastInfo.hasOwnProperty('playerType') ? this.podcastInfo.playerType : 'video'
    },
    exportDropdownOptions () {
      if (this.editMode === 'editTranscript') return editTranscriptExports
      return [...editMediaExports, ...editTranscriptExports]
    },
    publicAccess () {
      if (this.podcastInfo && this.user.isAnonymous) return this.podcastInfo.publicAccess || 'read'
      return 'false'
    },
    overallProgress () {
      // logic to calculate % progress
      let vm = this
      if (this.podcastInfoExists) return calculatePercentage(this.podcastInfo, vm.now)
      return {
        'progress': 0,
        'status': {
          code: 100,
          text: 'Downloading'
        }
      }
    },
    title: {
      get() {
        if (store.state.editor && store.state.editor.title) return store.state.editor.title
        return ''
      },
      set(newVal) {
        this.saveTitle(newVal)
        store.commit('editor/setTitle', newVal)
      }
    },
    getEndTimeValue: function () {
      if (this.exportStage === 3) return 99999
      if (this.exportStage < 3 && this.selectedClip) return this.selectedClip.end
      return this.totalTime
    },
    hasVideo() {
      return this.podcastInfo.hasOwnProperty('video_info') || this.podcastInfo.isClip || this.podcastInfo.hasVideo
    }
  },
  methods: {
    ...mapActions({
      // setDefaultPanel: 'editor/setDefaultPanel',
      togglePanel: 'editor/togglePanel',
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal',
      openBlockmode: 'blockmode/open',
      closeBlockmode: 'blockmode/close',
      resetVideo: 'video/resetVideo'
    }),
    handleClip: function(update) {
      this.$refs.clipper.clip(update)
    },
    onExportPlayToggled(isPlaying) {
      if (isPlaying && this.isPlaying) {
        this.toggleState()
      }
      store.commit('clipper/setExportClipPlaying', isPlaying)
    },
    onTourStart: function() {
      console.log('Tour started: HomeProse')
      this.touring = true
      if (this.isPlaying) this.toggleState()
    },
    onTourEnd: function() {
      this.touring = false
      this.toggleState()
      setOnboardingState(this.user.uid, '5')
      this.tourInitiated = true
      this.tourFinalDone = false
    },
    toggleSearchPanel: function() {
      this.togglePanel({ panel: 'search' })
    },
    toggleCommentsPanel: function() {
      this.togglePanel({ panel: 'comments' })
    },
    openInviteModal: function() {
      this.openModal({ name: 'InviteModal' })
      window.analytics.track('popup_inviteButtonClicked')
    },
    setTitle (mode) {
      if (mode === 'editTranscript' && this.needsSubscription) return this.openModal({ name: 'AskSubModal' })
      store.dispatch('editor/toggleMode', mode)
    },
    toggleEditMode: function() {
      if (this.editMode === 'editMedia') this.setTitle('editTranscript')
      else this.setTitle('editMedia')
    },
    cleanupSelections: function() {
      removeAllSearchHighlights()
      removeAllSelections()
    },
    copyLink () {
      copyToClipboard(window.location.href)
      this.showToast('Link Copied.')
    },
    openEditTranscriptFirstPopover () {
      this.openModal({ name: 'EditTranscriptPrompt', props: { selectOption: this.selectedEditTranscriptFirstOption } })
    },
    startClock () {
      let vm = this
      vm.intervalId = setInterval(function () {
        vm.now = Date.now()
        if ((vm.overallProgress.progress === 100 || vm.overallProgress.status.code >= 400) && vm.intervalId) {
          console.log('stopping timer')
          clearInterval(vm.intervalId)
          if (vm.overallProgress.progress === 100) {
            vm.initPodcast()
          }
        }
      }, 1000)
    },
    copyText () {
      window.analytics.track('popup_collaboration_copyLink')
    },
    showToast: function(msg = '', type = 'info') {
      this.$refs.toastr.Add({
        msg,
        type,
        title: type === 'info' ? '💡' : '',
        position: 'toast-bottom-center',
        progressbar: false,
        preventDuplicates: true,
        timeout: 4000
      })
    },
    showMessage: function(msgCode = 500) {
      this.showToast(errors[msgCode], msgCode >= 500 ? 'error' : msgCode >= 400 ? 'warning' : 'info')
    },
    updateContactInfo () {
      let vm = this
      let ref = firebase
        .database()
        .ref(vm.ownerId + '/podcast-info/' + vm.podId + '/collaborators').child(vm.user.uid)
      let key = uuidv1()
      let update = {
        lastSeen: Date().toString(),
        photoURL: vm.user.photoURL,
        displayName: vm.user.displayName,
        uid: vm.user.uid
      }
      update['online/' + key] = true
      ref.update(update)
      this.onlineRef = ref.child('online').child(key)
      this.onlineRef.onDisconnect().remove()
    },
    setLoadingState (loadingState) {
      // console.log('loadingState', loadingState)
      this.loading = loadingState
    },
    isNewDoc () {
      let vm = this
      let isNewDoc = localStorage[vm.podId] || false
      // console.log('isNewDoc', isNewDoc)
      return isNewDoc
    },
    setOldDoc () {
      let vm = this
      delete localStorage[vm.podId]
    },
    produce (format, options) {
      console.log('produce called')
      var vm = this
      var data = {
        'userid': vm.ownerId,
        'podid': vm.podId,
        'options': options,
        'flag_intro': false,
        'flag_outro': false
      }
      axios({
        method: 'post',
        url: CONSTANTS.POST_PRODUCTION,
        data: data
      }).then(function (response) {
        let data = response['data']
        if (data.error) {
          throw data.error
        }
        this.showMessage(203)
      }).catch(function (err) {
        console.log('err', err)
      })
    },
    repositionSelectionToolbar  () {
      window._spext_editorScrollTop = this.$refs.editorContainer.scrollTop
      let pos = window.selectionToolbar - this.$refs.editorContainer.scrollTop
      if (document.getElementById('selectionToolbar')) document.getElementById('selectionToolbar').style.top = pos + 'px'
      if (document.getElementById('correctTranscriptId')) document.getElementById('correctTranscriptId').style.top = pos + 'px'
    },
    switchOnListening () {
      document.addEventListener('keydown', this.keyDownListner)
      let vm = this

      vm.$refs.editorContainer.addEventListener('scroll', function () {
        window.requestAnimationFrame(function () {
          vm.closePopovers()
          // vm.repositionSelectionToolbar()
        })
      })

      if (CONSTANTS.RIGHT_CLICK_DISABLED) {
        // do not allow right click
        document.addEventListener(
          'contextmenu',
          function (e) {
            e.preventDefault()
            vm.showMessage(400)
          },
          false
        )
      } else {
        // not being used as of now. right click is not allowed
        if (CONSTANTS.SHOW_SAMPLE_FILE_MODAL_RIGHT_CLICK) {
          document.getElementById('proseText').addEventListener(
            'contextmenu',
            function (e) {
              e.preventDefault()
              $('.ui.sampleFile.modal').modal('show')
            },
            false
          )
        }
      }

      window.addEventListener('online', vm.networkChange)
      window.addEventListener('offline', vm.networkChange)
      document.addEventListener('keydown', vm.addKeyListeners)
      document.getElementById('editor').addEventListener('keydown', vm.addKeyListenerToDoc)
      document.getElementById('editor').addEventListener('keydown', vm.addSpaceKeyListener)
      // warning before leaving the tab
      $(document).ready(function () {
        window.onbeforeunload = vm.askConfirm
      })
      document.addEventListener('copy', vm.copyText)
    },
    switchOffListening () {
      document.removeEventListener('keydown', this.keyDownListner)
      let vm = this

      vm.$refs.editorContainer.removeEventListener('scroll', function () {
        window.requestAnimationFrame(function () {
          vm.closePopovers()
          if (document.getElementById('selectionToolbar')) document.getElementById('selectionToolbar').style.display = 'none'
        })
      })

      if (CONSTANTS.RIGHT_CLICK_DISABLED) {
        // do not allow right click
        document.removeEventListener(
          'contextmenu',
          function (e) {
            e.preventDefault()
            vm.showMessage(400)
          },
          false
        )
      } else {
        if (CONSTANTS.SHOW_SAMPLE_FILE_MODAL_RIGHT_CLICK) {
          document.getElementById('proseText').removeEventListener(
            'contextmenu',
            function (e) {
              e.preventDefault()
              $('.ui.sampleFile.modal').modal('show')
            },
            false
          )
        }
      }

      window.removeEventListener('online', vm.networkChange)
      window.removeEventListener('offline', vm.networkChange)
      document.removeEventListener('keydown', vm.addKeyListeners)
      try {
        const editor = document.getElementById('editor')
        if (editor) {
          document.getElementById('editor').removeEventListener('keydown', vm.addKeyListenerToDoc)
          document.getElementById('editor').removeEventListener('keydown', vm.addSpaceKeyListener)
        }
      } catch (err) {
        console.log('err', err)
      }
      document.removeEventListener('copy', vm.copyText)
    },
    getPodcastList (uid) {
      return firebase.database().ref(uid + '/podcast-info/').once('value')
    },
    showBlockModeOnboarding () {
      let vm = this
      vm.$refs.blockModePopover.activeTutorialIndex = 0
      store.dispatch('modal/showBlockPopover')
    },
    helpSelected (choice) {
      // console.log('choice', choice)
      let vm = this
      switch (choice) {
        case 'aboutMedia':
        case 'aboutTranscript':
          if (vm.shouldShowOnboarding()) this.$tours['HomeProse'].start()
          break
        case 'blockModeOnboarding':
          this.showBlockModeOnboarding()
          break
      }
    },
    closePopovers () {
      store.state.editor.addActionPopoverConfig.show = false
      store.state.editor.labelPopoverConfig.show = false
      store.dispatch('modal/closePopovers')
    },
    async addDoc (source) {
      // console.log('azzsdasda', source)
      /**
       * Add a existing doc to another document
       */
      if (source === '') {
        return
      }
      let [resourceid, ownerid] = source.split('#!@')
      if (!ownerid) {
        ownerid = this.user.uid
      }
      store.commit('doc/clearNewDoc')
      let vm = this
      try {
        let transcript = await instance.getVoiceTranscript(ownerid, resourceid)
        // console.log('transcript', transcript)
        transcript = await instance.filterDeletedTextFromTranscript(transcript)
        // console.log('after delete', transcript)
        // console.log(vm.isNewDoc(), vm.freshDoc)
        const addedPodcastInfo = (await getPodcastMeta(ownerid, resourceid).once('value')).val()
        if (addedPodcastInfo.hasOwnProperty('video_info') || addedPodcastInfo.isClip || addedPodcastInfo.hasVideo) {
          vm.updatePodcastWithVideoInfo()
        }
        if (vm.isNewDoc() && vm.freshDoc) {
          // console.log('loading New doc')
          // vm.loadJSON(transcript)
          // vm.downloadedAssets = true
          vm.setDOCC(transcript)
          vm.$refs.prose.insertDocAtPos(transcript, ownerid, resourceid)
          store.commit('editor/setFreshDoc', false)
          // store.commit('editor/setDirty', Date().toString())
        } else {
          // console.log('inserting New doc')
          vm.$refs.prose.insertDocAtPos(transcript, ownerid, resourceid)
        }
      } catch (err) {
        console.log('err', err)
      }
    },
    goToPara (index) {
      this.$refs.prose.goToPara(index)
    },
    goToTime(time) {
      this.$refs.prose.goToTime(time)
    },
    toggleState (computeSchedule = false) {
      if (this.selectedClip && this.currentTime >= this.selectedClip.end) {
        this.goToTime(this.selectedClip.start)
        setTimeout(() => {
          store.dispatch('editor/toggleState', true)
        }, 10)
      } else {
        store.dispatch('editor/toggleState', computeSchedule)
      }
    },
    backward () {
      this.$refs.audioController.backward()
    },
    forward () {
      this.$refs.audioController.forward()
    },
    updateSources (sources, mediaSources) {
      // console.log('admxckjhfsd', sources, mediaSources)
      // Get audio from all the sources
      let vm = this
      let tempList = []
      let tempVideoList = []
      let tempImageList = []

      sources.forEach(function (source) {
        if (source === 0 || source === '0' || source.split('#!@')[0] === 0 || source.split('#!@')[0] === '0') {
          // console.warn(`source can't be 0`)
          return
        }
        if (!(source in vm.urlList) && !(source in vm.loadingUrlList)) {
          let url = vm.getURLfromSource(source)
          let videoUrl = vm.getVideoUrlFromSource(source)

          if (url) {
            vm.loadingUrlList[source] = url
            tempList.push(url)
          }
          if (videoUrl) {
            tempVideoList.push(videoUrl)
          }
        }
      })
      mediaSources.forEach(function (source) {
        if (source === 0 || source === '0' || source.split('#!@')[0] === 0 || source.split('#!@')[0] === '0') {
          // console.warn(`source can't be 0`)
          return
        }
        if (!(source in vm.urlList) && !(source in vm.loadingUrlList)) {
          let imageUrl = vm.getImageUrlFromSource(source)
          if (imageUrl) {
            tempImageList.push(imageUrl)
          }
        }
      })
      if (this.isDemo) {
        // Todo Demo
        let source = CONSTANTS.demoWithNoiseSource
        let podcastWithNoise = CONSTANTS.demoWithNoiseAudio
        let url = firebase.storage().ref(podcastWithNoise).getDownloadURL().then(function (url) {
          return {
            source: source,
            url: url
          }
        })
        tempList.push(url)
      }

      Promise.all(tempImageList).then(function (values) {
        values.forEach(function (value) {
          if (value) {
            vm.$set(vm.imageUrlList, value.source, value.url)
          }
        })
      })

      Promise.all(tempVideoList).then(function (values) {
        const validValues = values.filter(t => Boolean(t))
        validValues.forEach(function (value) {
          vm.$set(vm.videoUrlList, value.source, value.url)
        })
      })
      Promise.all(tempList).then(function (values) {
        values.forEach(function (value) {
          if (value) vm.$set(vm.urlList, value.source, value.url)
        })
      })
    },
    async getVideoUrlFromSource(source) {
      // console.log('ssssssssss mp4', source)
      /**
       * Get path of video files based on source
       */
      // console.log('source is', source)
      const vm = this
      let [resourceid, ownerid] = source.split('#!@')
      if (!ownerid) {
        ownerid = this.ownerId
      }
      let path
      if (resourceid.startsWith('m-')) {
        // return null
        let contentType = null
        path = `podcast_audio/${ownerid}/uploaded_media/${resourceid}`
        await firebase.storage().ref(path).getMetadata()
          .then((metadata) => {
            // console.log('path metadata', metadata)
            contentType = metadata.contentType
          })
          .catch((error) => {
            console.log('path metadata error', error)
          })
        if (contentType !== 'video/mp4') {
          return null
        }
        // console.log('path', path)
      } else {
        path = `podcast_audio/${ownerid}/${resourceid}/video${this.fullscreen ? '_r' : ''}.mp4`
      }
      try {
        return firebase.storage().ref(path).getDownloadURL().then(function (url) {
          // console.log('ssssssssss mp4', {
          //   source: source,
          //   url: url
          // })
          return {
            source: source,
            url: url
          }
        }).catch(function (err) {
          console.error('Storage Error:', err)
          if (!resourceid.startsWith('m-') && vm.fullscreen) path = `podcast_audio/${ownerid}/${resourceid}/video.mp4`
          return firebase.storage().ref(path).getDownloadURL().then(function (url) {
            return {
              source: source,
              url: url
            }
          }).catch(function (err) {
            console.error('Storage Error:', err)
            return null
          })
        })
      } catch (err) {
        console.log('Storage Error:', err)
        return null
      }
    },
    getImageUrlFromSource(source) {
      // console.log('ssssssssss mp4', source)
      /**
       * Get path of video files based on source
       */
      // console.log('source is', source)
      let [resourceid, ownerid] = source.split('#!@')
      if (!ownerid) {
        ownerid = this.ownerId
      }
      let path
      if (resourceid.startsWith('i-')) {
        path = `podcast_audio/${ownerid}/uploaded_media/${resourceid}`

        try {
          return firebase.storage().ref(path).getDownloadURL().then(function (url) {
          // console.log('ssssssssss mp4', {
          //   source: source,
          //   url: url
          // })
            return {
              source: source,
              url: url
            }
          }).catch(function (err) {
            console.log('Storage Error:', err)
            return null
          })
        } catch (err) {
          console.log('Storage Error:', err)
          return null
        }
      }
    },
    getURLfromSource (source) {
      /**
       * Get path of audio files based on source
       */
      // console.log('source is', source)
      let [resourceid, ownerid] = source.split('#!@')
      if (!ownerid) {
        ownerid = this.ownerId
      }
      let path, path1, path2, path3
      if (resourceid.startsWith('m-')) {
        path = `podcast_audio/${ownerid}/uploaded_media/${resourceid}.wav`
        path1 = `podcast_audio/${ownerid}/uploaded_media/${resourceid}.mp3`
        path2 = `podcast_audio/${ownerid}/uploaded_media/${resourceid}.flac`
        path3 = `podcast_audio/${ownerid}/uploaded_media/${resourceid}`
      } else {
        if (this.browserIs === 'safari') {
          path = `podcast_audio/${ownerid}/${resourceid}/audio.mp3`
          path1 = `podcast_audio/${ownerid}/${resourceid}/audio.flac`
          path2 = `podcast_audio/${ownerid}/${resourceid}/audio.wav`
          path3 = this.podcastInfo.uri
        } else {
          path = `podcast_audio/${ownerid}/${resourceid}/audio.ogg`
          path1 = `podcast_audio/${ownerid}/${resourceid}/audio.mp3`
          path2 = `podcast_audio/${ownerid}/${resourceid}/audio.flac`
          path3 = this.podcastInfo.uri
        }
      }
      return firebase.storage().ref(path).getDownloadURL().then(function (url) {
        return {
          source: source,
          url: url
        }
      }).catch(function (err) {
        console.log(`Not found at ${path}`, err)
        return firebase.storage().ref(path1).getDownloadURL().then(function (url) {
          return {
            source: source,
            url: url
          }
        }).catch(function (err) {
          console.log(`Not found at ${path1}`, err)
          return firebase.storage().ref(path2).getDownloadURL().then(function (url) {
            return {
              source: source,
              url: url
            }
          }).catch(function (err) {
            console.log(`Not found at ${path2}`, err)
            return firebase.storage().ref(path3).getDownloadURL().then(function (url) {
              return {
                source: source,
                url: url
              }
            }).catch(function (err) {
              console.log('Storage Error:', source, path3, err)
            })
          })
        })
      })
    },
    keyDownListner: function(e) {
      const watchKeys = [
        70, // F
        77, // M
        83 // S
      ]

      if ((e.metaKey || e.ctrlKey) && watchKeys.includes(e.keyCode)) {
        e.preventDefault()
        if (e.keyCode === 70) this.togglePanel({ panel: 'search' })
        if (e.keyCode === 77) this.toggleEditMode()
        if (e.keyCode === 83) {
          this.fileState = {
            value: 'Saved',
            type: 'success'
          }
          setTimeout(() => {
            this.fileState = null
          }, 800)
        }
      }
    },
    addSpaceKeyListener (e) {
      /**
       * Edit media mode
       */
      let vm = this
      const modeBased = {
        32: 'toggleState'
      }
      const ACTIONS = {
        'toggleState': function () {
          console.log('space pressed')
          vm.toggleState()
        }
      }
      let truth = document.getElementById('correctTranscriptId') || store.state.editor.editMode !== 'editMedia'
      if ((!truth) && !e.shiftKey && e.keyCode in modeBased) {
        let action = modeBased[e.keyCode]
        e.preventDefault()
        ACTIONS[action](e)
        return false
      }
    },
    addKeyListenerToDoc (e) {
      /**
       * Edit media mode
       */
      let vm = this
      const modeBased = {
        229: 'mobileKeyUnidentified'// mobileKey:229, unidentified for backspace, etc
      }
      const ACTIONS = {
        'mobileKeyUnidentified': function () {
          vm.showMessage(501)
        }
      }
      if (vm.editMode !== 'editTranscript' && !e.shiftKey && e.keyCode in modeBased) {
        let action = modeBased[e.keyCode]
        e.preventDefault()
        ACTIONS[action](e)
        return false
      }
    },
    addKeyListeners (e) {
      /**
       * Transcript mode keyboard shortcuts
       */
      var vm = this
      // console.log('event is', e)
      const CTRL_ACTIONS = {
        'save': function (e) {
          e.preventDefault()
          vm.saveToFirebaseNow()
        },
        'addDoc': function () {
          console.log('add file name')
          e.preventDefault()
          let podid = prompt('Enter podid of other podcast')
          if (podid != null) {
            console.log('podid is ', podid)
            vm.addDoc(podid)
          }
        },
        'find': function () {
          console.log('find')
        },
        'paste': function (e) {
          // console.log('paste')
          store.commit('editor/setFreshDoc', false)
        }
      }

      const ACTIONS = {
        'onboarding': function () {
          if (vm.shouldShowOnboarding()) {
            vm.$tours['HomeProse'].start()
          }
        },
        'toggleState': function () {
          console.log('esc pressed')
          vm.closePopovers()
          vm.toggleState()
        }
      }

      const CTRL_MAP = {
        83: 'save', // S
        // 49: 'addDoc',
        86: 'paste'
        // 70: 'find'
      }

      const MAP = {
        113: 'onboarding', // f2
        27: 'toggleState' // Esc
      }
      let action = ''
      if ((e.ctrlKey || e.metaKey) && (e.keyCode in CTRL_MAP)) {
        action = CTRL_MAP[e.keyCode]
        CTRL_ACTIONS[action](e) // actions with ctrl or Cmd keys
        return false
      } else if (e.keyCode in MAP && !e.shiftKey) {
        action = MAP[e.keyCode]
        e.preventDefault()
        if (e.keyCode === 27 && (this.openModalName || this.notificationsOpen)) return false
        ACTIONS[action](e)
        return false
      }
    },
    setDOCC (json) {
      // console.log('json', json)
      docc = json
      store.commit('editor/setDirty', Date().toString())
      this.saveToFirebaseLater()
    },
    transcodeToFirebase (doc) {
      /**
       * Just before saving to the backend
       */
      // console.time('transcodeToFirebase', doc)
      let localDeleted = 0
      if (doc.content) {
        doc.content.forEach(function (paragraphNode) {
          if (paragraphNode.content) {
            paragraphNode.content.forEach(function (textNode) {
              let flag = false
              if (textNode.marks) {
                textNode.marks.forEach(function (mark) {
                  if (mark.type === 'deleted') {
                    flag = true
                  } else if (mark.type === 'info') {
                    if (flag) {
                      localDeleted += (mark.attrs.aend - mark.attrs.astart)
                    }
                  }
                })
              }
            })
          }
        })
      }

      store.commit('editor/setTimeDeleted', localDeleted * 1000)
      // console.timeEnd('transcodeToFirebase')
    },
    showChromeModal () {
      $('.ui.chrome.modal')
        .modal({
          closable: false
        })
        .modal('show')
    },
    closeChromeModal () {
      $('.ui.chrome.modal').modal('hide')
    },
    toggleReduceNoise () {
      let vm = this
      vm.$refs.prose.toggleReduceNoise()
      if (!vm.isPlaying) {
        setTimeout(function () {
          vm.toggleState(true)
        })
      }
    },
    // showVideo (type) {
    //   var vm = this
    //   vm.$refs.youtube.type = type
    //   console.log('show video', type)
    //   $('.ui.youtube.modal').modal({
    //     'detachable': false,
    //     onHide: function (el) {
    //       vm.$refs.youtube.stopVideo()
    //       return true
    //     }
    //   })
    //     .modal('show')
    // },
    downloadPostProcessed () {
      console.log('download pp')
      var vm = this
      vm.showMessage(204)
      vm.exportAudioLinkNewURL(
        vm.$parent.podcastList[vm.podId].exported_files.post_production.url,
        '.mp3'
      )
    },
    prettyMs (time) {
      return prettyMs(Math.abs(time))
    },
    checkInitialOnboarding: function() {
      // setOnboardingState(this.user.uid, '4') // for testing
      if (!this.isDemo && !this.fullscreen) {
        if (this.shouldShowOnboarding() && this.currentTourStep === '4') {
          this.$tours['HomeProse'].start()
        }
        // if (this.editMode === 'editTranscript') {
        //   if (!localStorage['onBoardingEditTranscript']) { this.openModal({ name: 'TranscriptOnboarding' }) }
        // } else {
        //   if (!localStorage['onBoardingEditMedia']) { this.openModal({ name: 'AudioOnboarding' }) }
        // }
      }
    },
    shouldShowOnboarding () {
      if (!this.podcastInfo) return 0
      const isAudioEditor = this.podcastInfo.audio_editor
      return (!this.isDemo && (isAudioEditor || this.isNewDoc()))
    },
    showOnBoarding () {
      if (this.editMode === 'editTranscript' && this.shouldShowOnboarding()) {
        this.$tours['HomeProse'].start()
      } else {
        this.openModal({ name: 'AudioOnboarding' })
      }
    },
    onStartFinalOnboarding(node) {
      if (!node) return
      this.showFinalTour = true
      document.querySelector('#editorContainer').style.overflow = 'initial'
      document.querySelector('body').style.pointerEvents = 'none'
      node.classList.add('v-tour__target--highlighted', 'v-tour__target--relative')
      node.style.padding = '0.55rem'
      node.style.margin = '-0.55rem'
      node.style.borderRadius = '5px'

      const dimensions = node.getBoundingClientRect()
      if (dimensions) this.tourFinalBoundaryRect = dimensions
      this.tourFinalDone = true
      this.tourInitiated = false
    },
    onEndFinalOnboarding: function() {
      setOnboardingState(this.user.uid, '6')
      const node = this.tourFinalNode

      document.querySelector('#editorContainer').removeAttribute('style')
      document.querySelector('body').removeAttribute('style')
      node.classList.remove('v-tour__target--highlighted', 'v-tour__target--relative')
      node.removeAttribute('style')

      const { from, to } = this.tourNodeHightlight
      const view = myProseEditor.view
      const state = view.state
      const transaction = state.tr
      transaction.removeMark(from, to, state.schema.marks.searchHighlight.create())
      view.dispatch(transaction)

      this.toggleState()
      this.showFinalTour = false
      this.tourFinalNode = null
    },
    showUploadModal () {
      this.$parent.showUploadModal()
    },
    closeUploadModal () {
      this.$parent.closeUploadModal()
    },
    showBillingModal (tab) {
      this.openModal({ name: 'AccountInfo', props: { tab } })
    },
    closeBillingModal () {
      $('.ui.billing.modal').modal('hide')
    },
    cleanUp (myFunction) {
      if (this.dirty) {
        this.saveToFirebaseNow()
        requestAnimationFrame(myFunction)
        return false
      }

      return true
    },
    goToDrive () {
      var vm = this
      vm.loading = 'Saving'
      if (this.cleanUp(this.goToDrive)) { // optimization : cleanup can be given to worker
        this.$router.replace({ name: 'drive' })
      }
    },
    updateProgress: debounce(function (progress) {
      let vm = this
      if (progress >= 1) {
        // console.log('asdasdas', store.state.editor.docVersion)
        if (store.state.editor.docVersion === 1) {
          vm.$refs.prose.migrateDoc()
          firebase.database().ref(firebase.auth().currentUser.uid + '/podcast-info/' + this.podId)
            .update({ version: 2 }).then(() => {
              store.commit('editor/setDocVersion', 2)
              console.log('written to db. Updated podcastInfo')
            }).catch((err) => {
              console.log('unable to save', err)
            })
        }
        // vm.$refs.prose.migrateDoc()
        if (!this.fullscreen) {
          this.initSmartEdit()
        }
        this.cleanupSelections()
        if (this.isDemo && !this.downloadedAssets) {
          // show onboarding
          setTimeout(() => {
            tippyTourAddClickPlay(vm.isMobile)
          }, 0)
        }
        this.downloadedAssets = true
        if (this.$route.query.exportFile) {
          let filename = this.$route.query.version
          store.state.editor.audioControl.exportWav(filename, this.masterTranscriptVolume)
        }
      }
    }, 750),
    saveTitle: debounce(
      function (newVal) {
        var vm = this
        firebase
          .database()
          .ref(vm.ownerId + '/podcast-info/' + vm.podId)
          .update({
            title: newVal
          })
          .then(function () {
            console.log('Written Title to Firebase')
          })
      },
      1000
    ),
    async initPodcast () {
      /**
       * Fetch transcript from firebaase db
       */
      let vm = this
      vm.loading = 'true'
      // here await won't come as it is getting used in Promise.all
      // if getting await error, please rebuild
      this.podcastInfoListener = getPodcastMeta(vm.ownerId, vm.podId)
      let flag = false
      this.podcastInfoListener.on('value', function (info) {
        let meta = info.val()
        console.log('checkhere', meta)
        if (!flag && meta) {
          flag = true

          // set mode, very important
          let mode = vm.$route.query.editMode || meta.audio_editor ? 'editMedia' : 'editTranscript' // getModeFromLocalStorage(vm.podId) : 'editTranscript'
          window.mode = mode
          store.commit('editor/setMode', mode)
          store.commit('editor/setTotalTime', meta.length)
          store.commit('editor/setTimeDeleted', meta.timeDeleted)
          store.commit('editor/setTitle', meta.title)
          store.commit('editor/setDocVersion', meta.version)
          if (meta.smartEditConfig) {
            console.log('smartedit exists')
            store.commit('smartEditConfig/setSmartEditConfig', meta.smartEditConfig)
          } else {
            console.log('smartedit empty')
            store.dispatch('smartEditConfig/initialiseSmartEditConfig', {
              podId: vm.$route.params.podId,
              ownerId: vm.$route.params.userId
            })
          }
        }
        store.commit('editor/setPodcastInfo', meta)
      })

      let textTranscript = instance.getTranscriptCorrected(vm.ownerId, vm.$route.params.podId)

      try {
        let transcript = await textTranscript

        // used for transcript-corrected
        // as its html parser cannot be moved to a worker
        if (typeof transcript === 'string') {
          transcript = formatToProse(transcript, vm.podId)
        }
        if (!transcript.doc.content || !transcript.doc.content.length) {
          store.commit('editor/setEditorBlocked', {
            value: true,
            reason: errors[404]
          })
          return
        }
        transcript = await instance.addUIDToSource(transcript, vm.ownerId) // adding source ( audio )
        vm.loadJSON(transcript) // load inside prosemirror
      } catch (err) {
        // when we are still waiting for the transcript
        vm.loading = 'Error'
        vm.podcastDeleted = true
        console.log({ err })
        if (err.message.substring(0, 17) === 'permission_denied') {
          store.commit('editor/setEditorBlocked', {
            value: true,
            reason: errors[403]
          })
        } else {
          // show progress as a circular bar
          vm.startClock()
        }

        // dont delete message
        // used by export server as flag to stop
        console.log('unable to load file')
        let anchor = document.createElement('a')
        anchor.setAttribute('id', 'downloadLink')
        anchor.setAttribute('err', 'yes')
        document.body.appendChild(anchor)
      }
    },
    loadJSON (json) {
      var vm = this
      // console.log('attr', json['attrs']'volume'])
      if (json['attrs'] && json['attrs']['volume']) {
        store.commit('editor/setMasterVolume', json['attrs']['volume'])
      }
      docc = json
      vm.$refs.prose.setContent(docc)
      vm.downloadedAssets = false
    },
    initSmartEdit() {
      if (!store.state.smartEditConfig.initialised) {
        disableFillerWords(this.podId, this.$route.params.userId)
        trimSilence(this.podId, this.$route.params.userId)
        store.dispatch('smartEditConfig/initialiseSmartEdit', {
          podId: this.$route.params.podId,
          ownerId: this.$route.params.userId
        })
        setTimeout(() => {
          if (!this.fullscreen && !this.touring) this.showAutopilotTooltip = true
        }, 3000)
      }
    },
    // setState (transcript) {
    //   let vm = this
    //   vm.$refs.prose.setState(transcript)
    // },
    async updatePodcastInfo () {
      // console.log('update podcastInfo')
      /* eslint-disable no-undef */
      var vm = this
      var updates = {}
      var updatedDate = Date().toString()
      updates['/podcast-info/' + vm.podId + '/updated/'] = updatedDate
      updates['/podcast-info/' + vm.podId + '/status/code/'] = 206
      updates['/podcast-info/' + vm.podId + '/status/msg/'] = 'correcting'
      updates['/podcast-info/' + vm.podId + '/timeDeleted/'] = vm.timeDeleted
      updates['/podcast-info/' + vm.podId + '/version/'] = 2
      try {
        console.log('checkhere', {
          ownerId: vm.ownerId,
          podId: vm.podId,
          updates
        })
        await instance.saveToDB(vm.ownerId, updates)
        console.log('written to db. Updated podcastInfo')
      } catch (err) {
        console.log('unable to save', err)
      }
    },
    saveToDB (uid, updates) {
      return firebase
        .database()
        .ref(uid)
        .update(updates)
    },
    saveToFirebaseNow: async function() {
      /* eslint-disable no-undef */
      var vm = this

      // Do not compute anything before this
      if (vm.dirty === vm.saving) {
        return
      }

      var start = window.performance.now()
      vm.saving = vm.dirty
      var updates = {}
      var updatedDate = vm.dirty

      vm.transcodeToFirebase(docc.doc)
      let { d } = compressStateJSON(docc)

      console.log('docc', docc.doc, d)
      updates['/podcast/' + vm.podId + '/realtime/checkpoint/'] = { d: d, k: docc.k, t: docc.t ? docc.t : '' }
      updates['/podcast-info/' + vm.podId + '/status/code/'] = 206
      updates['/podcast-info/' + vm.podId + '/status/msg/'] = 'correcting'
      updates['/podcast-info/' + vm.podId + '/timeDeleted/'] = vm.timeDeleted
      updates['/podcast-info/' + vm.podId + '/updated/'] = updatedDate
      updates['/podcast-info/' + vm.podId + '/length/'] = vm.totalTime
      updates['/podcast-info/' + vm.podId + '/version/'] = 2
      if (vm.isNewDoc()) {
        updates['/podcast-info/' + vm.podId + '/audio_editor/'] = true
      }
      try {
        await instance.saveToDB(vm.ownerId, updates)
        var end = window.performance.now()
        var time = end - start
        console.log(updatedDate)
        console.log('Transcript written. Status Updated')
        // if (vm.editMode === 'editMedia') {
        //   // console.log('removing transcript corrected')
        //   try {
        //     await instance.removeTranscriptCorrected(vm.ownerId, vm.podId)
        //   } catch (err) {
        //     console.log('unable to delete', err)
        //   }
        // } else if (vm.editMode === 'editTranscript') {
        //   // console.log('removing transcript corrected')
        //   try {
        //     await instance.removeOldTranscriptCorrected(vm.ownerId, vm.podId)
        //   } catch (err) {
        //     console.log('unable to delete', err)
        //   }
        // }
        if (vm.dirty && vm.dirty <= updatedDate) {
          store.commit('editor/setDirty', '')
          store.commit('editor/setDirtyMessage', 0)
          vm.saving = ''
        }
        if (vm.isNewDoc()) {
          vm.setOldDoc()
        }
      } catch (err) {
        console.log('unable to save', err)
      }
    },
    clearCorrected () {
      var vm = this
      firebase
        .database()
        .ref(
          vm.ownerId +
            '/podcast/' +
            vm.podId +
            '/editor_corrected/'
        )
        .remove()
      vm.getPodcast(prevLink)
    },
    savingMessage () {
      this.saveNow()
      vm.showMessage(505)
    },
    exportAsFormat (type, options) {
      let vm = this
      console.log('type', type)
      switch (type) {
        case 'doc':
          break
        case 'sesx':
          vm.exportSESX()
          break
        default:
          vm.exportCompleteAudio(type, options)
      }
      this.togglePanel({ panel: 'exports', forceShow: true })
    },
    exportAs (format) {
      window.analytics.track('drive_exportClicked', { type: format })
      this.selectedExportFormat = format
      switch (format) {
        case 'txt':
        case 'srt':
        case 'vtt':
        case 'fcpxml':
        case 'sesx':
        case 'doc':
          if (this.editMode === 'editMedia' && !localStorage['onBoardingEditTranscriptFirstPopover']) {
            this.openEditTranscriptFirstPopover()
          } else {
            if (this.needsSubscription) return this.openModal({ name: 'AskSubModal' })

            this.exportTextFormat(format)
          }
          break
        case 'mp4':
          this.exportAsFormat(this.selectedExportFormat)
          break
        default:
          // TODO: directly post produce (Noise reduction)
          if (!this.isNoiseReductionEnabled) {
            this.exportAsFormat(this.selectedExportFormat)
          } else {
            const settings = {
              denoise: Array.isArray(this.nrConfig) && this.nrConfig.includes('cleanBgNoise'),
              normloudness: Array.isArray(this.nrConfig) && this.nrConfig.includes('boostSpeaker'),
              leveler: Array.isArray(this.nrConfig) && this.nrConfig.includes('voiceLevelling')
            }
            const options = {
              leveler: true,
              normloudness: true,
              loudnesstarget: true,
              hipfilter: true,
              denoise: true,
              denoiseamount: true,
              ...settings
            }

            this.exportAsFormat(this.selectedExportFormat, options)
          }
      }
    },
    exportTextFormat (format) {
      if (myProseEditor) {
        switch (format) {
          case 'txt':
          case 'srt':
          case 'vtt':
          case 'doc':
            exportAsFile(myProseEditor.view.state, this.title, format)
            break
          case 'fcpxml':
          case 'sesx':
            this.exportAsFormat(this.selectedExportFormat)
            this.togglePanel({ panel: 'exports', forceShow: true })
            break
          default:
            exportAsFile(myProseEditor.view.state.doc.toJSON(), this.title, 'doc')
            this.togglePanel({ panel: 'exports', forceShow: true })
            break
        }
      }
    },
    syncSubtitle: function() {
      if (myProseEditor) this.subtitle = formatAsSubtitleNew(myProseEditor.view.state)
    },
    selectedEditTranscriptFirstOption (event) {
      this.closeModal()
      if (event.export) {
        this.exportTextFormat(this.selectedExportFormat)
      } else {
        toggleTranscriptMode()
      }
    },

    exportSESX () {
      let vm = this
      let falsey = getExportMessageDontAsk()
      console.log('getEditMediaDontAsk', falsey)
      if (falsey) {
        vm.showMessage(201)
      } else {
        this.openModal({ name: 'ExportMessage' })
      }
      let version = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      let body = {
        userid: vm.ownerId,
        media_id: vm.podId,
        vid: version,
        format: 'sesx'
      }
      axios.post(`${CONSTANTS.SESSION_EXPORT}`, body)
        .then(function (res) {
          if (falsey) {
            vm.showMessage(200)
          }
          console.log(res)
        }).catch(function (err) {
          console.log('log: exportSESX -> err', err)
          vm.showMessage(502)
        })
    },
    exportCompleteAudio (format, options = null) {
      var vm = this
      let falsey = getExportMessageDontAsk()
      console.log('getEditMediaDontAsk', falsey)
      if (falsey) {
        vm.showMessage(201)
      } else {
        this.openModal({ name: 'ExportMessage' })
      }
      let version = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      let exFormat = {}
      exFormat[format] = true
      saveExportedFileMeta(this.ownerId, this.podId, version, format === 'mp4' ? 'mp4' : 'wav', {
        status: 'start',
        timestamp: { '.sv': 'timestamp' },
        startedBy: this.user.uid,
        options: options,
        format: exFormat
      }, function() {
        if (falsey) {
          vm.showMessage(200)
        }
      },
      function() {
        vm.showMessage(502)
      })

      // firebase.database().ref(`${this.ownerId}/podcast-info/${this.podId}/exported_files/${version}/wav`)
      //   .set({
      //     status: 'start',
      //     timestamp: { '.sv': 'timestamp' },
      //     startedBy: this.user.uid,
      //     options: options,
      //     format: exFormat
      //   }).then(function () {
      //     if (falsey) {
      //       vm.showMessage(200)
      //     }
      //   }).catch(function () {
      //     vm.showMessage(502)
      //   })
    },
    exportAudioLinkNewURL (uri, type) {
      var vm = this
      var url = uri
      console.log('fullPath', url, url.indexOf('http'))
      if (url.indexOf('http') >= 0) {
        vm.exportAudioLink(url, type)
      } else {
        var storage = firebase.storage()
        var httpsReference = storage.ref(url)
        httpsReference.getDownloadURL().then(function (newUrl) {
          console.log('url is', newUrl)
          // vm.getPodcast(podId, new_url, new_url, title)
          vm.exportAudioLink(newUrl, type)
        })
      }
    },
    exportAudioLink (uri, type) {
      var a = document.createElement('a')
      a.href = uri
      a.download = this.title + type
      a.target = '_blank'
      a.click()
    },
    networkChange (e) {
      if (e.type === 'offline') {
        this.offline = true
      } else {
        this.offline = false
      }
    },
    saveNow: function () {
      store.commit('editor/setDirty', Date().toString())
      this.saveToFirebaseNow()
    },
    saveToFirebaseLater: debounce(
      // Save to firebase 2 minutes later
      function () {
        this.saveToFirebaseNow()
      },
      1000
    ),
    askConfirm: function () {
      if (this.dirty) {
        // Put your custom message here
        this.saveToFirebaseNow()
        return 'Your unsaved data will be lost.'
      }
    },
    stopAudio () {
      var vm = this
      vm.$refs.prose.$refs.player.stopPlayer()
    },
    async updatePodcastWithVideoInfo() {
      const vm = this
      const updates = {}
      updates['/podcast-info/' + vm.podId + '/media_id'] = vm.podId
      updates['/podcast-info/' + vm.podId + '/hasVideo'] = true
      try {
        await vm.saveToDB(vm.ownerId, updates)
        store.commit('editor/setPodcastInfo', { ...this.podcastInfo, hasVideo: true, media_id: vm.podId })
        console.log('Updated new video state')
      } catch (err) {
        console.log('unable to save new video state', err)
      }
    }
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      }
    }
  },
  beforeDestroy () {
    this.closeBlockmode()
    if (this.onlineRef) {
      this.onlineRef.remove()
    }
    if (this.podcastInfoListener) {
      this.podcastInfoListener.off()
    }
    myProseEditor.stopAudioPlayer()
    store.commit('editor/reset')
    // console.log('Destroying HomeAudio')
    this.switchOffListening()
    this.urlList = {}
    this.videoUrlList = {}
    this.loadingUrlList = {}
    this.resetVideo()
  }
}
</script>

<style lang="less">
@import './HomeProse.less';
</style>
