<template>
  <div
    class="border w-full bg-white flex flex-col h-full"
  >
      <div class="flex flex-col items-center h-full w-full relative test">
        <div
          class="
            flex flex-col
            space-y-3.5
            justify-center
            w-full
            px-1.5
            py-4
          "
        >
          <div
            class="font-h font-semibold px-3 flex justify-between items-center"
          >
            <div class="cursor-pointer" @click="toggleClips">Clips</div>
            <div
              v-if="displayPreviousExportsButton"
              @click="displayExported"
              class="
                font-ui font-normal
                text-sm
                border-b-2
                cursor-pointer
                text-primary
                border-primary
              "
              style="line-height: 17px"
            >
              View past exports
            </div>
          </div>
        </div>
        <div class="w-full justify-center items-center flex pb-2">
          <div class="w-full flex items-center border-primary-400 border-solid border text-xs text-primary px-5 py-2.75 mx-3 rounded-md">
            <span>💡</span>
            <span class="ml-2">Remember exporting these clips first, before editing the document further.</span>
          </div>
        </div>
        <div class="w-full relative scroller">
            <div v-if="exportStage < 3" class="px-3 flex flex-col gap-2 h-full w-full max-h-full overflow-hidden overflow-y-scroll">
              <PreviewClip
                v-for="clip in clips"
                :key="clip.index"
                :id="`previewClip-${clip.index}`"
                :clip="clip"
                :totalTime="totalTime"
                :isSelected="selectedClip && selectedClip.index === clip.index"
                :isPlaying="
                  isPlaying && selectedClip && selectedClip.index === clip.index
                "
                :loading="clipsLoading"
                :showDelete="true"
                :editable="true"
                :showDuration="true"
                :nonBrandkitSource="nonBrandkitSource"
                @onTogglePlay="playClip(clip)"
                @onDelete="deleteClip(clip)"
                @onSelect="selectClip(clip)"
                @onUpdate="editTitle"
                @mouseenter.native="addClipHover"
                @mouseleave.native="removeClipHover"
              />
            </div>
            <div v-else-if="exportStage === 3" class="px-3 flex flex-col gap-2 h-full w-full max-h-full overflow-hidden overflow-y-scroll">
              <PreviewClip
                v-for="clip in previewClips"
                :key="`${clip.title}-${clip.index}`"
                :id="`previewClip-${clip.index}`"
                :clip="clip"
                :totalTime="totalTime || 1000"
                :isSelected="selectedClip && selectedClip.index === clip.index"
                :isPlaying="isPlaying && selectedClip && selectedClip.index === clip.index"
                :loading="clipsLoading"
                :showDelete="false"
                :editable="false"
                :showDuration="true"
                :nonBrandkitSource="nonBrandkitSource"
                :additionalDuration="additionalDuration"
                @onTogglePlay="playClip(clip)"
                @onSelect="selectClip(clip)"
              />
            </div>
        </div>

        <div class="w-full flex flex-col px-4 py-2 h-12 border-t-2 border-solid border-primary-400 shadow-custom">
          <ExportClipButton
            v-on="$listeners"
            :title="clips.length === 1 ? 'Export Clip' : 'Export All Clips'"
            :isExporting="isExporting"
          >
          </ExportClipButton>
        </div>
      </div>
  </div>
</template>

<script>
import ExportClipButton from './ExportClipButton'
import PreviewClip from '@/view/Workflows/components/PreviewClip.vue'

export default {
  name: 'Clips',
  components: { ExportClipButton, PreviewClip },
  props: {
    displayPreviousExportsButton: {
      type: Boolean,
      default: false
    },
    isExporting: {
      type: Boolean,
      default: false
    },
    clips: {
      type: Array,
      default: () => []
    },
    clipsLoading: {
      type: Boolean,
      default: false
    },
    selectedClip: {
      type: Object,
      default: () => null
    },
    totalTime: {
      type: Number,
      default: 0
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    nonBrandkitSource: {
      type: String,
      default: ''
    },
    previewClips: {
      type: Array,
      default: () => []
    },
    exportStage: {
      type: Number,
      default: 1
    },
    additionalDuration: {
      type: Number,
      default: 0
    }
  },
  methods: {
    playClip(clip) {
      this.$emit('togglePlay', clip)
    },
    deleteClip(event) {
      this.$emit('deleteClip', event)
    },
    selectClip(clip) {
      this.$emit('selectClip', clip)
    },
    exportClick() {
      this.$emit('exportClips')
    },
    editTitle(event) {
      this.$emit('editTitle', event)
    },
    addClipHover(e) {
      this.$emit('addClipHover', e)
    },
    removeClipHover(e) {
      this.$emit('removeClipHover', e)
    },
    displayExported() {
      if (this.hide) {
        setTimeout(() => {
          this.$emit('oldExportedClips')
        }, 400)
      } else {
        this.$emit('oldExportedClips')
      }
    },
    toggleClips() {
      this.$emit('toggleClips')
    }
  }
}
</script>

<style scoped>
.scroller {
  height: calc(100% - 162px);
}
.shadow-custom {
  box-shadow: 0px -7px 40px -10px rgba(0,0,0,0.75);
}
</style>
