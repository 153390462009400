import {
  ObjectLiteral
} from '../../../../../../models'

const ERROR_CODES: ObjectLiteral = {
  400: 'Unable to access the link, Please check.',
  401: 'File format is not supported.<br>Please convert it to one of the known formats.',
  402: 'We need sample rate to be greater than 16000 for this language.',
  403: 'We need sample rate to be greater than 8000 for English language.',
  404: 'There is some problem with the audio! Can not calculate the Sample rate.',
  500: `Error in downloading the audio.<br>Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">let us know</a> of this event.`,
  501: 'Not enough balance in account. Please add a topup.',
  502: `Error in audio processing, format conversion.<br>Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">let us know</a> in chat`,
  503: `Our server is facing a problem in transcribing.<br>Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">reach out to us</a>.`,
  504: 'File length is more than 3 hrs, Please reduce the length and try again.',
  505: 'Can not determine the size of the file.',
  506: `File size is too big for us to process. Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">reach out to us</a> for enterprise plans.`,
  600: `File not transcribed due to a server issue.<br>Please <a style="text-decoration:underline;color:#ed6a5a;cursor:pointer;" onclick="window.Intercom('showNewMessage')">reach out to us</a>.`
}

export function calculatePercentage (rawPodcast: any, now: number) {
  const podcast = {
    'progress': 0,
    'doc_progress': 0
  }

  let audioEditorStatus = 'false'
  let audioEditorProgress = 0
  const result = {
    'progress': 0,
    'status': {
      code: 100,
      text: 'Downloading'
    }
  }
  const audioLengthMS = rawPodcast['length'] * 1000
  const twentyMinMS = 20 * 60 * 1000
  const timeElapsed = now - Date.parse(rawPodcast['created'])
  let totalExpectedTime = 1
  if (audioLengthMS > twentyMinMS) {
    totalExpectedTime = audioLengthMS
  } else {
    totalExpectedTime = twentyMinMS
  }

  if (rawPodcast.status.code < 200) {
    result.status.code = rawPodcast.status.code
    result.status.text = 'Starting'
    podcast['progress'] = 0
  } else if (rawPodcast.status.code >= 200 && rawPodcast.status.code <= 204) {
    podcast['progress'] = statusToPercentage(rawPodcast.status.code, timeElapsed, totalExpectedTime)
    result.status.code = rawPodcast.status.code
    result.status.text = 'Starting'
  } else if (rawPodcast.status.code > 204) {
    podcast['progress'] = 1
    result.status.code = rawPodcast.status.code
    result.status.text = 'Done'
  }

  // find audioEditorStatus & calculate audioEditorProgress
  if (rawPodcast.audio_editor === true) {
    audioEditorStatus = 'true'
  } else if (rawPodcast.audio_editor === false) {
    audioEditorStatus = 'false'
  } else if (rawPodcast.audio_editor === 'processing') {
    audioEditorStatus = 'processing'

    // calculate audioEditorProgress
    if (rawPodcast.align_status && rawPodcast.seg_count) {
      let progress = 0
      for (const i in rawPodcast.align_status) {
        progress += rawPodcast.align_status[i]
      }
      const percentageProgress = progress / rawPodcast.seg_count
      audioEditorProgress = (percentageProgress * 50 + podcast.progress * 50)
    } else {
      audioEditorProgress = (podcast.progress * 50)
    }
  }

  if (rawPodcast.status.code in ERROR_CODES) {
    result.status.code = rawPodcast.status.code
    result.status.text = ERROR_CODES[rawPodcast.status.code]
    podcast['doc_progress'] = 0
  } else {
    if (audioEditorStatus === 'true') {
      podcast['doc_progress'] = 100
    } else if (audioEditorStatus === 'processing') {
      if (audioEditorProgress < 100) {
        podcast['doc_progress'] = audioEditorProgress
      } else {
        podcast['doc_progress'] = 100
      }
    } else {
      if (podcast['progress'] < 1) {
        podcast['doc_progress'] = parseFloat((podcast['progress'] * 100).toFixed(2))
      } else {
        podcast['doc_progress'] = 100
      }
    }
  }
  // contact us
  // if (podcast['doc_progress'] < 100 && new Date() - new Date(podcast.updated) > 3600000) {
  //   podcast['doc_progress'] = 0
  // }
  // Queued
  if (rawPodcast.status.code === 200) {
    podcast['doc_progress'] = 1
  }
  result['progress'] = parseFloat((podcast['doc_progress']).toFixed(2))
  return result
}

function statusToPercentage (status: number, timeElapsed: number, totalExpectedTime: number) {
  let lStatus = status
  if (status === 100) {
    lStatus = 199
  }
  const statuses: ObjectLiteral = {
    198: 0,
    199: 0,
    200: 5,
    201: 10,
    202: 20,
    203: 80,
    204: 100,
    205: 100
  }
  const percentage = (timeElapsed / totalExpectedTime)
  const statusProgress = statuses[lStatus - 1] / 100
  const nextProgress = statuses[lStatus] / 100
  if (percentage < 1) {
    return (percentage * (nextProgress - statusProgress)) + statusProgress
  } else {
    return statusProgress
  }
}
