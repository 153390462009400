import { render, staticRenderFns } from "./HomeProse.vue?vue&type=template&id=51fda2e6&"
import script from "./HomeProse.vue?vue&type=script&lang=js&"
export * from "./HomeProse.vue?vue&type=script&lang=js&"
import style0 from "./HomeProse.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports