// This is the wrapper component that will wrap the right panel in clipper
<template>
  <div class="w-full h-full">
    <ClipperUI
      ref="clipperUI"
      :isExporting="exportStage > 1"
      :exportedClips="exportedClips"
      :clips="clips"
      :previewClips="previewClips"
      :clipsLoading="loadingPreviewClips"
      :selectedClip="selectedClip"
      :totalTime="totalTime"
      :isPlaying="isPlaying"
      :nonBrandkitSource="nonBrandkitSource"
      :exportStage="exportStage"
      :additionalDuration="additionalDuration"
      :exportClip="exportClip"
      @exportClips="onExportClick"
      @selectClip="onClipSelect"
      @togglePlay="togglePlay"
      @deleteClip="deleteClip"
      @editTitle="editTitle"
      @addClipHover="addClipHover"
      @removeClipHover="removeClipHover"
      @onShare="handleEClipShare"
      @onTogglePlay="handleEClipPlay"
    />
    <div v-if="exportLoading" class="fixed inset-0 " />
  </div>
</template>
<script>
import Clipper from '@/view/voiceEditor/proseEditor/workflows/clipper'
import {
  checkForAttrInParentNodes,
  checkForIdInParentNodes,
  computeTotalDuration,
  myProseEditor,
  getUidFromPillElementId
} from '@/view/voiceEditor/proseEditor/util/utility'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { getProjects } from '@/services/api/project'
import store from '@/services/store'
import ClipperUI from '@/components/ClippersModule/ClipperUI.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'Wrapper',
  data() {
    return {
      isLoading: true,
      initialProjectId: ''
    }
  },
  components: {
    ClipperUI
  },
  /**
   * Sets up deselect event listener
   * Gets all the projects this user has and creats a list of
   * past exports to show them all in one place. Also initalizes
   * clipper class.
   */
  mounted() {
    this.setupClipDeselectEventListener()
    this.isLoading = true
    getProjects({ userId: this.user.uid }).once('value', a => {
      const projects = a.val()
      if (projects && Object.keys(projects).length) {
        this.setupProjects(Object.values(projects))
      } else {
        this.initClipperWithView('')
      }
      this.isLoading = false
    })
  },
  computed: {
    ...mapGetters({
      user: 'app/user',
      clips: 'clipper/clips',
      exportedClips: 'clipper/exportedClips',
      clipper: 'clipper/clipper',
      useBrandkit: 'clipper/useBrandkit',
      brandkit: 'clipper/brandkit',
      podcastInfo: 'editor/podcastInfo',
      isPlaying: 'editor/isPlaying',
      sourcesReadyMap: 'video/sourcesReadyMap',
      previewClip: 'clipper/selectedClip',
      previewClips: 'clipper/previewClips',
      exportStage: 'clipper/exportStage',
      loadingPreviewClips: 'clipper/loadingPreviewClips',
      exportClip: 'clipper/exportClip',
      exportLoading: 'clipper/exportLoading',
      currentTime: 'doc/currentTime',
      panelToShow: 'editor/panelToShow',
      isBlockmodeOpen: 'editor/isBlockmodeOpen'
    }),
    selectedClip: {
      get() {
        return this.previewClip
      },
      set(val) {
        store.commit('clipper/setSelectedClip', val)
      }
    },
    totalTime: debounce(() => {
      if (myProseEditor && myProseEditor.view) {
        return computeTotalDuration(myProseEditor.view)
      } else return 0
    }, 500),
    nonBrandkitSource() {
      let sourceIds
      if (this.clipper) {
        sourceIds = Object.keys(this.sourcesReadyMap || {}).filter(
          el =>
            !this.clipper.introSources.includes(el) &&
            !this.clipper.outroSources.includes(el)
        )
      } else {
        sourceIds = Object.keys(this.sourcesReadyMap || {})
      }
      return sourceIds.length ? this.sourcesReadyMap[sourceIds[0]] : null
    },
    everythingLoaded() {
      return this.nonBrandkitSource && !this.isLoading && this.totalTime
    },
    additionalDuration() {
      let duration = 0
      if (this.brandkit) {
        const { intro, outro } = { ...this.brandkit }
        if (intro && intro.duration) duration += intro.duration
        if (outro && outro.duration) duration += outro.duration
      }

      return duration
    }
  },
  methods: {
    ...mapActions({
      openExportModal: 'clipper/openExportModal'
    }),
    ...mapMutations({
      setClipper: 'clipper/setClipper'
    }),
    setupProjects(projects) {
      const unexportedProjs = []
      projects.forEach(proj => {
        const isValidProject =
          !proj.deleted &&
          proj.hasOwnProperty('isShownOnDrive') && !proj.isShownOnDrive &&
          proj.doc &&
          proj.doc.key === this.podcastInfo.media_id
        if (!isValidProject) return
        if (proj.hasOwnProperty('clips')) {
          store.dispatch('clipper/appendExportedClips', proj.clips)
        } else {
          unexportedProjs.push(proj)
        }
      })
      if (unexportedProjs.length === 0) {
        this.initClipperWithView('')
      } else if (unexportedProjs.length === 1) {
        this.initClipperWithView(unexportedProjs[0].uid)
      } else {
        const sortedProjects = unexportedProjs.sort((a, b) => b.updated - a.updated)
        this.initClipperWithView(sortedProjects[0].uid)
      }
    },
    /**
     * As we do not have any sort of way of know when myProseEditor is initialized,
     * if we try to directly initialize the clipper class, myproseeditor is sometimes
     * null. Causing the entire clipper flow to go in an errored state.
     */
    initClipperWithView(projectId) {
      const vm = this
      const mpeInterval = setInterval(() => {
        if (myProseEditor !== null) {
          vm.initClipper(projectId)
          clearInterval(mpeInterval)
        }
      }, 100)
    },
    /**
     * Initialises a clipper instance.
     * @param projectId [Optional] The id of the project with which
     * we need to initialise the project. Will create a new one if not
     * provided.
     */
    initClipper(projectId) {
      if (!this.podcastInfo.media_id) return
      const docData = {
        key: this.podcastInfo.media_id,
        title: this.podcastInfo.title || 'Untitled Doc',
        owner: this.user.uid
      }
      if (projectId) {
        this.setClipper(
          new Clipper(
            this.user,
            docData,
            myProseEditor.view,
            this.manageUpdates,
            projectId
          )
        )
      } else {
        this.setClipper(
          new Clipper(this.user, docData, myProseEditor.view, this.manageUpdates)
        )
      }
    },
    /**
     * Takes updates and sets the relavent ones into vuex store.
     * @param projectInfo The project data recieved from firebase.
     */
    manageUpdates(projectInfo) {
      store.commit('clipper/setClips', projectInfo.timestamps || [])
      store.commit('clipper/setUseBrandkit', projectInfo.useBrandkit || false)
      store.commit('clipper/setBrandkit', projectInfo.brandkit || null)
      store.dispatch('clipper/appendExportedClips', projectInfo.clips || [])
      this.updateSelectedClip()
    },
    /**
     * Creates a clip with the given data
     */
    clip(update) {
      if (this.clipper) {
        if (this.panelToShow !== 'shortcuts') {
          store.commit('editor/setPanelToShow', 'shortcuts')
        }
        if (this.isBlockmodeOpen) store.dispatch('blockmode/close')
        update.index = update.id
        delete update.id
        this.clipper.clip(update)
        this.selectedClip = update
      }
    },
    /**
     * Sets a mousedown event listener which checks if there is
     * any active clip. If there is, then it checks where the click was.
     * If the user clicks at the clip, its highlight, the clip preview,
     * or the video player, the clip isn't removed, else it is.
     * Is destroyed on unmount
     */
    setupClipDeselectEventListener() {
      window.addEventListener('mousedown', e => {
        if (this.exportStage >= 2) return
        const valid = (this.selectedClip && this.clipper.activeClip) || this.exportClip
        if (!valid) return
        let ids = []
        let attr = ''
        if (this.selectedClip) {
          ids = ['startclip-', 'endclip-', 'previewClip-', 'previewPlayer']
          attr = 'data-cliphl_uid'
        }
        if (this.exportClip) {
          ids.push('exportClip-')
        }
        if (!ids.length) return
        const isAnyPill = ids.some(val =>
          checkForIdInParentNodes(e.target, val)
        )
        const isAnyHighlight = attr && checkForAttrInParentNodes(e.target, attr)
        if (!(isAnyPill || isAnyHighlight)) {
          this.deselectClip()
        }
      })
    },
    /**
     * Called when a clip is selected. Handles clip creation and selection.
     */
    onClipSelect(clip) {
      if (!clip) return
      if (this.exportClip) store.commit('clipper/setExportClip', null)
      if (this.exportStage < 3) {
        this.removeClipHover()
        if (this.selectedClip) {
          if (clip.index === this.selectedClip.index) {
            if (this.currentTime >= clip.end) {
              this.$parent.goToTime(clip.start)
            }
            return
          } else if (this.exportStage < 2) {
            this.clipper.removeClipPills()
          }
        }
        this.$parent.goToTime(clip.start)
        this.selectedClip = { ...clip }
        if (this.exportStage < 2) {
          this.clipper.createClipPills(
            clip.location.start,
            clip.location.end,
            clip.index
          )
        }
      } else {
        if (this.selectedClip && clip.index === this.selectedClip.index) return
        this.selectedClip = { ...clip }
      }
    },
    /**
     * Handles clip deselect. Removes clip pills and sets selectedClip to null
     */
    deselectClip() {
      if (this.selectedClip) {
        this.selectedClip = null
        if (this.isPlaying) {
          store.dispatch('editor/toggleState')
        }
        this.clipper.removeClipPills()
        if (this.exportStage === 3) {
          this.clipper.resetDocState()
        }
      } else if (this.exportClip) {
        store.commit('clipper/setExportClip', null)
      }
    },
    /**
     * Updates the doc state to the given state
     */
    updateDocState: function(state) {
      myProseEditor.view.updateState(state)
      myProseEditor.registerComputeOnDispatchTransaction()
      this.$parent.$refs.previewPlayer.syncSubtitle()
    },
    /**
     * Called on export button click
     */
    onExportClick() {
      this.openExportModal(false)
    },
    /**
     * toggles clip playing state. Also selects the clip.
     */
    togglePlay(clip) {
      this.onClipSelect(clip)
      setTimeout(() => {
        store.dispatch('editor/toggleState', true)
      }, 100)
    },
    /**
     * Permanently deletes the clip
     */
    deleteClip(clip) {
      this.clipper.deleteClip(clip.index)
      if (this.selectedClip && this.selectedClip.index === clip.index) {
        this.selectedClip = null
      }
    },
    /**
     * Called to edit a clip's title
     */
    editTitle(clipUpdate) {
      const clips = [...this.clips]
      clips.forEach(clip => {
        if (clip.index === clipUpdate.id) {
          clip.title = clipUpdate.title
        }
      })
      this.selectedClip = { ...this.selectedClip, title: clipUpdate.title }
      this.clipper.updateProject({
        timestamps: clips
      })
    },
    /**
     * Triggers hover mark addition.
     */
    addClipHover(e) {
      const id = getUidFromPillElementId(e.target.id)
      const clipHovered = this.clips.find(clip => clip.index === id)
      this.clipper.addClipHover(clipHovered)
    },
    /**
     * Removes any hover that is currently active
     */
    removeClipHover() {
      this.clipper.removeClipHover()
    },
    /**
     * Opens the given url and copies it to the clipBoard
     */
    handleEClipShare(url) {
      window.open(url, '_blank')
      copyToClipboard(url)
    },
    /**
     * Sets the exportClip as the given clip
     */
    handleEClipPlay(clip) {
      const isSameClipClicked =
        this.exportClip && this.exportClip.uid === clip.uid
      if (isSameClipClicked) {
        if (this.$parent.$refs.exportPlayer) { this.$parent.$refs.exportPlayer.togglePlay() }
      } else store.dispatch('clipper/setExportClip', clip)
    },
    updateSelectedClip() {
      if (!this.selectedClip) return
      if (this.exportStage < 3) {
        this.clips.forEach(clip => {
          if (clip.index === this.selectedClip.index) {
            this.selectedClip = clip
          }
        })
      } else {
        this.previewClips.forEach(clip => {
          if (clip.index === this.selectedClip.index) {
            this.selectedClip = clip
          }
        })
      }
    }
  },
  watch: {
    selectedClip(val) {
      if (this.exportStage === 3 && val) {
        this.updateDocState(val.clipState)
        const vm = this
        setTimeout(() => {
          vm.$parent.goToTime(0)
        }, 100)
      }
    },
    exportStage(val) {
      if (val === 2) {
        this.onClipSelect(this.clips[0])
      }
    }
  },
  beforeDestroy() {
    store.dispatch('clipper/onUmount')
    window.removeEventListener('mousedown', () => {}, true)
    if (this.selectedClip) this.clipper.removeClipPills()
  }
}
</script>
