<template>
  <div
    class="bg-white border border-gray-300 w-full max-h-full justify-between h-full"
  >
    <div
      class="flex flex-col gap-3.5 justify-end w-full max-h-full px-1.5 pt-5"
    >
      <div
        class="font-h font-semibold px-3 flex gap-5 items-center"
        @click="fromClips ? goBack() : toggleClips()"
      >
        <div class="cursor-pointer ml-2 transform rotate-180" v-show="fromClips">
          <svg
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 15L1 8L8 1"
              stroke="#111827"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="font-h cursor-pointer">Exported Clips</div>
      </div>
      <div class="px-3 w-full h-full overflow-y-scroll">
        <div class="pl-2.5 flex flex-col gap-3.5 m-auto w-full pb-22"
        >
          <ExportClip
            v-for="el in (makingClips ? timestamps : clips)"
            v-on="$listeners"
            :key="makingClips ? el.index : el.uid"
            :id="makingClips ? el.index : el.uid"
            :clip="el"
            :working="makingClips"
            :selected="selectedClips.some(t => t.uid === el.uid)"
            :isPlaying="exportClip && exportClip.uid === el.uid && exportClip.playing"
            :selectable="false"
            ref="exportClips"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExportClip from '@/view/Workflows/components/ExportClip.vue'

export default {
  components: { ExportClip },
  props: {
    fromClips: {
      type: Boolean,
      default: false
    },
    isExported: {
      type: Boolean,
      default: true
    },
    clips: {
      type: Array,
      default: () => []
    },
    timestamps: {
      type: Array,
      default: () => []
    },
    makingClips: {
      type: Boolean,
      default: false
    },
    selectedClips: {
      type: Array,
      default: () => []
    },
    exportClip: {
      type: Object,
      default: () => {}
    },
    isContracted: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goBack() {
      this.$emit('backToClips')
    },
    toggleClips() {
      this.$emit('toggleExported')
    }
  }
}
</script>

<style scoped>
.ease-in-out-modal-enter {
  transform: translate(0, 100%);
}
.ease-in-out-modal-leave {
  transform: translate(0, 100%);
}
.ease-in-out-modal-leave-to {
  transform: translate(0, 100%);
}
</style>
