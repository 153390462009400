<template>
  <div ref="tourHint" class="wrapper" :style="{ left: `${axis.x + 26}px`, top: `${axis.y + 51}px`, transform: `translatex(-50%)` }">
    <div class="mb-6">
      <div class="text-lg leading-6 text-primary">One last thing!</div>
      <p class="text-primary-600">You can add other media to your file too!</p>
      <div class="flex flex-col gap-3 mt-1">
        <div class="flex items-center gap-2">
          <CheckIcon classname="w-4.5 h-4.5"/>
          <p class="leading-4">Click on a word to add media before it, or</p>
        </div>
        <div class="flex items-center gap-2">
          <CheckIcon classname="w-4.5 h-4.5"/>
          <p class="leading-4">Select a sentence to add overlapping media</p>
        </div>
      </div>
      <div class="flex items-end justify-around mt-3">
        <img style="width: 8.75rem" :src="require('@/assets/images/internal-onboarding-1.jpeg')">
        <img style="width: 13.16rem" :src="require('@/assets/images/internal-onboarding-2.jpeg')">
      </div>
    </div>
    <div class="flex items-center justify-end">
      <Button class="" variant="primary" @click="onComplete()">
        Got It 👍
      </Button>
    </div>
    <span class="wrapper_box"/>
  </div>
</template>

<script>
import CheckIcon from '@/components/base/icons/Check.vue'

export default {
  name: 'TooltipMenuHintPopup',
  components: {
    Button: () => import('@/components/base/buttons/Button.vue'),
    CheckIcon
  },
  data() {
    return {
      axis: { x: this.domBoundaryRect.x >= 260 ? this.domBoundaryRect.x : 260, y: this.domBoundaryRect.y }
    }
  },
  props: {
    domBoundaryRect: {
      type: DOMRect,
      default: null
    },
    onComplete: {
      type: Function
    }
  }
}
</script>

<style scoped>
  .wrapper {
    background: #fff;
    color: #000228;
    width: 512px;
    border-radius: 8px;
    box-shadow: 0px 20px 25px -5px rgb(0 0 0 / 10%), 0px 10px 10px -5px rgb(0 0 0 / 4%);
    padding: 1.5rem;
    pointer-events: auto;
    z-index: 10000;
    position: absolute;
  }
  .wrapper_box {
    position: absolute;
    left: 50%;
    top: -0.44rem;
    width: 0.88rem;
    height: 0.88rem;
    background-color: white;
    display: inline-block;
    transform: rotate(45deg);
  }
</style>
