<template>
  <div class="block w-full h-full overflow-hidden mb-24" :class="{
    'padding-5':!seeAll
  }">
    <div class="p-4 text-lg font-semibold font-h text-primary flex items-center justify-between">
      Keyboard Shortcuts
      <div
        v-if="seeAll"
        class="cursor-pointer text-primary-800 hover:text-primary transform rotate-180"
        v-tooltip.top="'Collapse All'"
        @click="seeAll = false;"
      >
        <ChevronDown />
      </div>
    </div>

    <ul class="mt-1 custom-max overflow-hidden overflow-y-auto">
      <li class="flex items-center justify-between px-4 py-3.5 border-b border-primary-200"
      v-for="shortcut in (seeAll ? shortcuts : shortcuts.slice(0, isTranscriptMode ? 3 : 5))"
      :key="shortcut.title">
        <span class="text-xs leading-4 text-primary">{{shortcut.title}}</span>

        <div class="text-xs">
          <span class="px-2 py-1 border rounded-sm border-primary-200 bg-primary-100 text-primary">{{shortcut.text}}</span>
          <span v-if="shortcut.secondaryText" class="text-primary-500">
            <span v-if="shortcut.joiner">{{shortcut.joiner}}</span>
            <span v-else>+</span>
          </span>
          <span v-if="shortcut.secondaryText" class="px-2 py-1 border rounded-sm border-primary-200 bg-primary-100 text-primary">{{shortcut.secondaryText}}</span>
          <span v-if="shortcut.tertiaryText" class="text-primary-500">
            <span v-if="shortcut.joiner">{{shortcut.joiner}}</span>
            <span v-else>+</span>
          </span>
          <span v-if="shortcut.tertiaryText" class="px-2 py-1 border rounded-sm border-primary-200 bg-primary-100 text-primary">{{shortcut.tertiaryText}}</span>
        </div>
      </li>

      <div v-show="!seeAll" class="px-4 pt-2">
        <Button
          variant="secondary"
          classname="w-full justify-center"
          @click="seeAll = true;"
        >
          See All
        </Button>
      </div>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from '@/services/store'
import Button from '@/components/base/buttons/Button.vue'
import ChevronDown from '@/components/base/icons/ChevronDown.vue'

export default {
  name: 'KeyboardShortcuts',
  data: () => ({
    seeAll: false
  }),
  components: {
    Button,
    ChevronDown
  },
  computed: {
    ...mapGetters({
      isTranscriptMode: 'editor/isTranscriptMode',
      editMode: 'editor/editMode'
    }),
    meta () {
      return store.state.app.meta
    },
    editMode () {
      return store.state.editor.editMode
    },
    shortcuts () {
      let editMediaShortcuts = [
        {
          title: '------------',
          icons: [],
          text: 'Represents Silence'
        },
        {
          title: 'Play/Pause',
          icons: [],
          text: 'Spacebar'
        },
        {
          title: 'Correct Transcript',
          icons: [],
          text: this.meta,
          secondaryText: 'K'
        },
        {
          title: 'Delete a word',
          icons: [],
          text: 'Backspace'
        },
        {
          title: 'Undo last action',
          icons: [],
          text: this.meta,
          secondaryText: 'Z'
        },
        {
          title: 'Add a file',
          icons: [],
          text: '+',
          joiner: 'or',
          secondaryText: '='
        },
        {
          title: 'Search',
          icons: [],
          text: this.meta,
          secondaryText: 'F'
        },
        {
          title: 'Cut Audio',
          icons: [],
          text: this.meta,
          secondaryText: 'X'
        },
        {
          title: 'Restore Audio',
          icons: [],
          text: this.meta,
          secondaryText: 'B'
        },
        {
          title: 'Copy Audio',
          icons: [],
          text: this.meta,
          secondaryText: 'C'
        },
        {
          title: 'Paste Audio',
          icons: [],
          text: this.meta,
          secondaryText: 'V'
        },
        // {
        //   title: 'Save Progress',
        //   icons: [],
        //   text: this.meta,
        //   secondaryText: 'S'
        // },
        // {
        //   title: 'Walkthrough',
        //   icons: [],
        //   text: 'F2'
        // },
        {
          title: 'Redo last action',
          icons: [],
          text: this.meta,
          secondaryText: 'Shift',
          tertiaryText: 'Z'
        },
        {
          title: 'Add Silence',
          icons: [],
          text: 'Shift',
          secondaryText: 'Spacebar'
        }
      ]
      let editTranscriptShortcuts = [
        {
          title: 'Correct word',
          icons: [],
          text: 'return'
        },
        {
          title: 'Switch to Media Editing',
          icons: [],
          text: this.meta,
          secondaryText: 'K'
        },
        {
          title: 'Undo last action',
          icons: [],
          text: this.meta,
          secondaryText: 'Z'
        },
        {
          title: 'Redo last action',
          icons: [],
          text: this.meta,
          secondaryText: 'Shift',
          tertiaryText: 'Z'
        },
        {
          title: 'Search',
          icons: [],
          text: this.meta,
          secondaryText: 'F'
        },
        {
          title: 'Add Space',
          icons: [],
          text: 'Shift',
          secondaryText: 'Spacebar'
        },
        {
          title: 'Bold',
          icons: [],
          text: this.meta,
          secondaryText: 'B'
        },
        {
          title: 'Italic',
          icons: [],
          text: this.meta,
          secondaryText: 'I'
        },
        {
          title: 'Underline',
          icons: [],
          text: this.meta,
          secondaryText: 'U'
        },
        {
          title: 'Highlight',
          icons: [],
          text: this.meta,
          secondaryText: 'E'
        }
        // {
        //   title: 'Save Progress',
        //   icons: [],
        //   text: this.meta,
        //   secondaryText: 'S'
        // },
        // {
        //   title: 'Walkthrough',
        //   icons: [],
        //   text: 'F2'
        // }
      ]
      let oldEditTranscriptShortcuts = [
        {
          title: 'Play/Pause',
          icons: [],
          text: 'ESC'
        },

        {
          title: 'Search',
          icons: [],
          text: this.meta,
          secondaryText: 'F'
        },
        {
          title: 'Undo last action',
          icons: [],
          text: this.meta,
          secondaryText: 'Z'
        },
        {
          title: 'Redo last action',
          icons: [],
          text: this.meta,
          secondaryText: 'Shift',
          tertiaryText: 'Z'
        },
        // {
        //   title: 'Add Space',
        //   icons: [],
        //   text: 'Shift',
        //   secondaryText: 'Spacebar'
        // },
        {
          title: 'Bold',
          icons: [],
          text: this.meta,
          secondaryText: 'B'
        },
        {
          title: 'Italic',
          icons: [],
          text: this.meta,
          secondaryText: 'I'
        },
        {
          title: 'Underline',
          icons: [],
          text: this.meta,
          secondaryText: 'U'
        },
        {
          title: 'Highlight',
          icons: [],
          text: this.meta,
          secondaryText: 'E'
        }
        // {
        //   title: 'Save Progress',
        //   icons: [],
        //   text: this.meta,
        //   secondaryText: 'S'
        // }
        // {
        //   title: 'Walkthrough',
        //   icons: [],
        //   text: 'F2'
        // }
      ]
      if (this.isTranscriptMode) return editTranscriptShortcuts
      else if (this.editMode === 'editTranscript') return oldEditTranscriptShortcuts
      return editMediaShortcuts
    }
  }
}
</script>
<style scoped>
.padding-5 {
  padding-right: 5px;
}

.custom-max {
  max-height: calc(100% - 140px);
}
</style>
