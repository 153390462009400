import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { clipPill } from '../../CustomNodes'
import ClipPill from '../../CustomNodes/clipper'
import { createProject, getProjectInfo, updateProject } from '@/services/api/project'
import firebase, { User } from 'firebase'
import { computeTimeAtPos, removeAllClipStuff } from '../../util/utility'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
import { Brandkit, Clip, ClippedDocParams, ExportedClip, PreviewClip, Project, SubtitleStyle } from '@/types/clipperTypes'
import { getBrandkitInfoOnce } from '@/services/api/brandkit'
import { addAudioVideoTime, addImageTime, addInlineMediaAtEnd, computeTotalDuration, createAndCopyDocLocation, createAndCopyDocTime, createClippedDocFromLocation, saveDoc } from '../../util/transoformationUtility'
import { v1 as uuidv1 } from 'uuid'
import { saveExportedFileMeta, updatePodcast } from '@/services/api/podcast'

export default class Clipper {
  /**
   * For pill creation, interaction and deletion.
   *
   * It uses the same global ClipPill class initialization as manual clipper as they
   * both run on the same schema.
  */
  clipPill: ClipPill
  public activeClip: string = ''
  user: User
  docData: Project['doc']
  view: EditorView
  docState: EditorState
  /** projectKey can only exist if there is at least one clip, thus the undefined. */
  projectKey: string | undefined
  projectInfo: Project | undefined
  introSources: string[] = []
  outroSources: string[] = []
  updatesRef: firebase.database.Reference | undefined
  emitUpdates: (projectInfo: Project) => void
  /**
   * @param user Firebase User object
   * @param docData Parent document information
   * @param existingProject Allows us to get data from a project which was never exported
      so the user can continue with that exisiting project instead of making a new one.
      This will make sure we don't have any redundant projects in our database.
  */
  constructor(
    user: User,
    docData: Project['doc'],
    view: EditorView,
    emitUpdates: (projectInfo: Project) => void,
    existingProjectId?: string
  ) {
    this.clipPill = clipPill
    this.user = user
    this.docData = docData
    this.view = view
    this.docState = this.view.state
    Object.assign(this.docState, this.view.state)
    this.emitUpdates = emitUpdates
    if (existingProjectId) {
      this.projectKey = existingProjectId
      this.listenToProject()
    } else {
      this.createProjectAndSetProjectInfo()
    }
  }

  /**
   * Creates a project specifically for clipper
  */
  private async createProject() {
    const projectKey: string = await createProject(this.user.uid, {
      brandkitType: 'video',
      isShownOnDrive: false,
      path: 'projects',
      projectType: 'Video Clipper',
      timestamps: [],
      confirmedClips: false,
      useBrandkit: false,
      doc: this.docData
    })

    return projectKey
  }

  /**
   * Creates a new project and initializes a listener for its updates.
   */
  private async createProjectAndSetProjectInfo() {
    this.projectKey = await this.createProject()
    this.listenToProject()
  }
  /**
   * Updates firebase with the provided project data
   * @param update An object containing the desired update. Type is a subest of the Project type
   * @returns The udpated project data
   */
  public async updateProject(update: Partial<Project>): Promise<void> {
    if (!this.projectKey) return
    await updateProject({
      userId: this.user.uid,
      projectId: this.projectKey,
      update
    })
  }
  /**
   * Sets an event listener for updates in project and accordingly updates vuex store.
   * @returns void
   */
  private async listenToProject() {
    if (!this.projectKey) return
    this.updatesRef = getProjectInfo({ userId: this.user.uid, projectId: this.projectKey })
    this.updatesRef.on('value', snap => {
      this.projectInfo = snap.val() as Project
      this.emitUpdates(this.projectInfo)
    })
  }

  public stopListeningToProject() {
    if (!this.updatesRef) return
    this.updatesRef.off()
  }

  /**
   * Creates clip pills on the selected location and
   * @param update the clip that needs to be saved
   */
  public async clip(update: Clip) {
    if (!this.projectKey || !this.projectInfo) return
    if (update.end - update.start === 0) return
    if (this.activeClip) {
      this.removeClipPills()
    }
    const clips: Clip[] = [...(this.projectInfo.timestamps || [])]
    if (!clips.length) {
      const editorInstance = myProseEditor as any
      // resets the docState if there were no clips before.
      this.docState = editorInstance.view.state as EditorState
    }
    clips.push(update)
    await this.updateProject({
      timestamps: clips
    })
    this.createClipPills(update.location.start, update.location.end, update.index)
  }
  /**
   * Creates clip pills in the editor
   * @param start start position of the clip
   * @param end end position of the clip
   * @param id id of the clip
   */
  public async createClipPills(start: number, end: number, id: string) {
    this.activeClip = this.clipPill.createPills(this.view, start, end, id)
    this.listenToEdits(id)
  }

  /**
   * Listens to edits to the active clip and calls editPillDuration
   * @param id id of the clip
   */
  private listenToEdits(id: string) {
    this.clipPill.PillEvents[id]
      .eventBus
      .on(`clip-edit.${id}`, (
        startPos: number,
        endPos: number,
        uid: string
      ) => this.editPillDuration(startPos, endPos, uid))
  }

  /**
   * Edits pill duration according to new positions given.
   * @param startPos new start position in the doc
   * @param endPos new end position in the doc
   * @param uid index of the clip
   * @returns void. Updates the project.
   */
  private editPillDuration(startPos: number, endPos: number, uid: string) {
    if (!this.projectInfo) return
    const clips = [...this.projectInfo.timestamps]
    const pillSize = clipPill.PillEvents[uid].pillNodeSize
    // Time has to be computed taking pills into consideration because
    // at this point, pills are visible on the document.
    const start = startPos + pillSize
    const end = endPos + pillSize
    const startTime = computeTimeAtPos(start, this.view)
    const endTime = computeTimeAtPos(end, this.view)
    clips.forEach(val => {
      if (val.index === uid) {
        val.start = startTime
        val.end = endTime
        // The values saved have to be irrespective of pill size.
        val.location.start = startPos
        val.location.end = endPos
      }
    })
    this.updateProject({
      timestamps: clips
    })
  }

  /**
   * Permanently deletes the clip corresponding to the given id.
   * @param id The id of the clip to be deleted
   */
  public async deleteClip(id: string) {
    if (!this.projectInfo) return
    const clips = [...this.projectInfo.timestamps]
    const indexOfClipToDelete = clips.findIndex(clip => clip.index === id)
    clips.splice(indexOfClipToDelete, 1)
    if (id === this.activeClip) this.removeClipPills()
    this.clipPill.removePillPreviewHover(this.view)
    this.updateProject({
      timestamps: clips
    })
  }

  public async deleteAllClips() {
    if (!this.projectInfo) return
    setTimeout(() => {
      removeAllClipStuff(this.view)
    }, 100)
    await this.updateProject({
      timestamps: []
    })
  }

  /**
   * Removes the active clip's pills and highlight from the editor.
   */
  public removeClipPills() {
    if (!this.activeClip) return
    this.clipPill.deletePillsByUid(this.view, this.activeClip)
    this.resetDocState()
    this.activeClip = ''
  }

  /**
   * Adds a grey mark for the duration of a given clip on the editor.
   * Also considers exisiting clips on the editor in order to avoid incorrect highlighting.
   * @param clip The clip, whose length needs to displayed on the editor
   * @returns void
   */
  public addClipHover(clip: Clip) {
    if (!this.projectInfo) return
    let start = 0
    let end = 0
    if (this.activeClip) {
      if (clip.index === this.activeClip) return
      const clipEdited = this.projectInfo.timestamps.find((clip: Clip) => clip.index === this.activeClip)
      if (!clipEdited) return
      if (clip.location.end < clipEdited.location.start) {
        // hoveredClip starts and ends before startpill
        start = clip.location.start
        end = clip.location.end
      } else if (clip.location.start < clipEdited.location.start && clip.location.end >= clipEdited.location.end) {
        // hoveredClip starts before startpill and ends after endpill
        start = clip.location.start
        end = clip.location.end + 2 * clipPill.pillNodeSize
      } else if (clip.location.start < clipEdited.location.start && clip.location.end >= clipEdited.location.start) {
        // hoveredClip starts before startpill and ends before endpill
        start = clip.location.start
        end = clip.location.end + clipPill.pillNodeSize
      } else if (clip.location.start >= clipEdited.location.start && clip.location.end < clipEdited.location.end) {
        // hoveredClip falls between start and end pill
        start = clip.location.start + clipPill.pillNodeSize
        end = clip.location.end + clipPill.pillNodeSize
      } else if (clip.location.start >= clipEdited.location.start && clip.location.end >= clipEdited.location.end) {
        // hoveredClip starts between start and end pill but ends after endpill
        start = clip.location.start + clipPill.pillNodeSize
        end = clip.location.end + (2 * clipPill.pillNodeSize)
      } else if (clip.location.start >= clipEdited.location.end) {
        // hoveredClip is beyond activeClip
        start = clip.location.start + (2 * clipPill.pillNodeSize)
        end = clip.location.end + (2 * clipPill.pillNodeSize)
      }
    } else {
      start = clip.location.start
      end = clip.location.end
    }
    this.clipPill.createPillPreviewHover(this.view, start, end)
  }

  /**
   * Removes the last added clip hover.
   * This also implies that more than one hover can't be active at a time.
   */
  public removeClipHover() {
    this.clipPill.removePillPreviewHover(this.view)
  }

  public async setBrandkit() {
    if (!this.projectInfo) return
    const brandkit: Brandkit = await getBrandkitInfoOnce({
      userId: this.user.uid,
      type: this.projectInfo.brandkitType,
      version: 'v1'
    })

    await this.updateProject({
      brandkit
    })
    return brandkit
  }

  public async setUseBrandkit(useBrandkit: boolean) {
    if (!this.projectInfo) return
    await this.updateProject({
      useBrandkit
    })
  }

  public async makePreviewClips(): Promise<PreviewClip[] | void> {
    if (!this.projectInfo) return
    const docState = this.docState
    if (!docState) return
    const { useBrandkit, brandkit } = this.projectInfo
    const { intro = {}, outro = {} } = { ...brandkit }
    if (intro && intro.key) this.introSources = [...this.introSources, `${intro.key}#!@${this.user.uid}`]
    if (outro && outro.key) this.outroSources = [...this.outroSources, `${outro.key}#!@${this.user.uid}`]
    this.view.updateState(docState)
    // A hacky way because myProseEditor doesn't have type definitions yet
    const editorInstance = myProseEditor as any
    editorInstance.registerComputeOnDispatchTransaction()
    const previewClips: PreviewClip[] = await Promise.all(this.projectInfo.timestamps.map(ts => new Promise(async (resolve: (value: PreviewClip) => void) => {
      let clipState: EditorState
      clipState = (await createClippedDocFromLocation(docState, ts.location.start, ts.location.end)).state

      if (useBrandkit) {
        if (outro.url) {
          clipState = await addInlineMediaAtEnd(clipState, outro.name, `${outro.key}#!@${this.user.uid}`, outro.duration)
        }

        if (intro.url) {
          clipState = await addAudioVideoTime(clipState, intro.url, intro.name, `${intro.key}#!@${this.user.uid}`, 0, 0, intro.duration)
        }
      }
      const duration = computeTotalDuration(clipState)
      const finalClip: PreviewClip = {
        ...ts,
        duration,
        clipState,
        ...useBrandkit && intro.key ? { introAsource: `${intro.key}#!@${this.user.uid}` } : {},
        ...useBrandkit && outro.key ? { outroAsource: `${outro.key}#!@${this.user.uid}` } : {}
      }
      resolve(finalClip)
    })))
    return previewClips
  }

  public resetDocState() {
    const docState = this.docState
    const editorInstance = myProseEditor as any
    editorInstance.view.updateState(docState)
    editorInstance.registerComputeOnDispatchTransaction()
  }

  private async makeClippedDoc({ key, owner, start, end, location, title, mainDocState }: ClippedDocParams): Promise<ExportedClip> {
    const version = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.projectInfo) throw new Error('No project info')
        const { useBrandkit, brandkit } = this.projectInfo
        const {
          intro = {},
          outro = {},
          logo = {},
          logoStyle = {},
          subtitleStyle = {},
          enableSubtitleFormatting
        } = { ...brandkit }
        const clipDocId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
        const clipCopyDocId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
        const clipOwnerId = this.user.uid
        const clip: ExportedClip = {
          uid: clipDocId,
          owner: clipOwnerId,
          version,
          start,
          end,
          title,
          docId: key,
          docOwner: owner,
          subtitleStyle: useBrandkit && enableSubtitleFormatting ? subtitleStyle as SubtitleStyle : null,
          copyDocId: clipCopyDocId,
          createdAt: Date.now(),
          duration: end - start
        }
        // save copy
        let { state: clipStateCopy, latestKey: latestKeyCopy } = await createAndCopyDocTime(mainDocState, start, end, clipCopyDocId, clipOwnerId, title)
        await saveDoc(clipStateCopy, clipOwnerId, clipCopyDocId, firebase, latestKeyCopy)
        let clipState: EditorState, latestKey: Number, clippedDoc: { state: EditorState, latestKey: number }, clipDuration: number
        clippedDoc = await createAndCopyDocLocation(mainDocState, location.start, location.end, clipDocId, clipOwnerId, title)
        clipState = clippedDoc.state
        latestKey = clippedDoc.latestKey
        if (useBrandkit) {
          // add outro
          if (outro.url) {
            clipState = await addInlineMediaAtEnd(clipState, outro.name, `${outro.key}#!@${clipOwnerId}`, outro.duration)
            console.log('project outro', clipState)
          }

          // add intro
          if (intro.url) {
            clipState = await addAudioVideoTime(clipState, intro.url, intro.name, `${intro.key}#!@${clipOwnerId}`, 0, 0, intro.duration)
            console.log('project intro', clipState)
          }

          // add logo
          clipDuration = computeTotalDuration(clipState)
          clip.duration = clipDuration
          if (logo.url) {
            clipState = addImageTime(clipState, logo.name, `${logo.key}#!@${clipOwnerId}`, 0, clipDuration, logoStyle)
            console.log('project logo', clipState)
          }
        }
        await saveDoc(clipState, clipOwnerId, clipDocId, firebase, latestKey)
        resolve(clip)
      } catch (err) {
        reject(err)
      }
    })
  }

  private async asyncUpdateClip(uid: string, update: any) {
    return new Promise((resolve, reject) => {
      updatePodcast(this.user.uid, uid, update)
        .then(resolve)
        .catch(reject)
    })
  }

  public async makeClips(): Promise<void> {
    if (!this.projectInfo || !this.projectKey) return
    const userId = this.user.uid
    const projectId = this.projectKey
    const { key, owner } = this.docData
    const mainDocState = this.docState

    const clips = await Promise.all(this.projectInfo?.timestamps.map(
      clip => this.makeClippedDoc({
        key,
        owner,
        start: clip.start,
        end: clip.end,
        location: clip.location,
        title: clip.title,
        mainDocState
      })
    ))

    await Promise.all([
      this.deleteAllClips(),
      Promise.all(clips.map((el, i) => this.asyncUpdateClip(el.uid, {
        subtitleStyle: el.subtitleStyle,
        deleted: true,
        updated: Date().toString(),
        isClip: true,
        projectId,
        clipId: el.uid,
        clipIndex: i
      }))),
      Promise.all(clips.map(el => this.asyncUpdateClip(el.copyDocId, {
        updated: Date().toString(),
        isClip: true
      }))),
      updateProject(
        {
          userId,
          projectId: this.projectKey,
          update: { clips, confirmedClips: true }
        }
      )
    ])
    await this.exportClips()
  }

  private async exportClips(): Promise<void> {
    if (!this.projectInfo) return
    const { clips } = this.projectInfo
    await Promise.all(clips.map(
      (el: ExportedClip) => this.asyncExportClip(el)
    ))
  }

  private async asyncExportClip({ uid, owner, version }: ExportedClip) {
    return new Promise((resolve, reject) => {
      saveExportedFileMeta(owner, uid, version, 'mp4', {
        status: 'start',
        timestamp: Date.now(),
        startedBy: owner
      },
      resolve,
      reject
      )
    })
  }
}
