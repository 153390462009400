<template>
  <button
    @click="emitClick()"
    class="
      flex
      base
      items-center
      justify-center
      text-xs
      w-full
      font-ui font-medium
      uppercase
      px-4
      py-3
      rounded-xl
      border border-primary
      text-white
      bg-primary
      h-10
    "
  >
    {{ isExporting ? "Exporting..." : title }}
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Export Clip'
    },
    isExporting: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClick() {
      this.$emit('exportClips', {
        export: true
      })
    }
  }
}
</script>

<style scoped>
.base {
  letter-spacing: 0.04em;
}
</style>
